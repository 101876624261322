import React from "react";

import type { User } from "client/src/app/liat/queries/useInitData/useInitData";

import { truncateSubsetName } from "../../../quickView/helpers";
import type IQuickViewAccount from "../../../quickView/types";
import type { QuickViewTranslations, RetirementIncome } from "../../types";
import InvestmentMix from "../InvestmentMix";
import Match from "../Match";

type QuickViewHeaderProps = {
    planDetails: IQuickViewAccount;
    retirementIncomeTranslations: RetirementIncome;
    showInvestmentMix: boolean;
    translations: QuickViewTranslations;
    userData: User[] | undefined;
};
const QuickViewHeader = ({
    planDetails,
    retirementIncomeTranslations,
    showInvestmentMix,
    translations,
    userData
}: QuickViewHeaderProps) => {
    const truncatedPlanName = truncateSubsetName(planDetails.planName);
    const truncatedSubsetName = truncateSubsetName(planDetails.subsetName);

    return (
        <div className="quickview-header-container">
            <div className="quickview-header">
                <p
                    data-testid="plan-name"
                    aria-labelledby="planName"
                    className="quickview-header-title"
                >
                    {truncatedPlanName}
                </p>
                <div className="sr-only" id="planName">
                    {planDetails.planName}
                </div>
                {truncatedSubsetName && (
                    <>
                        <p
                            data-testid="subset-name"
                            aria-labelledby="subsetName"
                            className="quickview-header-subtitle"
                        >
                            {truncatedSubsetName}
                        </p>
                        <div className="sr-only" id="subsetName">
                            {planDetails.subsetName}
                        </div>
                    </>
                )}
            </div>
            <div className="quickview-header-alerts">
                <InvestmentMix
                    retirementIncomeTranslations={retirementIncomeTranslations}
                    userData={userData}
                    showInvestmentMix={showInvestmentMix}
                />
                <Match maxMatch={planDetails.maxMatch} translations={translations} />
            </div>
        </div>
    );
};

export default QuickViewHeader;
