import type { Tier } from "../../types";

type GetEquityPctLabelArgs = {
    equityPct: number | undefined;
    equityPctRanges: Tier[];
};

const getEquityPctLabel = ({ equityPct, equityPctRanges }: GetEquityPctLabelArgs) => {
    const percentage = equityPct ? equityPct * 100 : 0;
    const range =
        equityPct !== undefined
            ? equityPctRanges.find((item: Tier) => percentage >= item.min && percentage <= item.max)
            : undefined;

    return range ? `${range.label} mix` : "";
};

export default getEquityPctLabel;
