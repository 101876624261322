import React from "react";

import { useGetPrimaryPlan } from "core-ui/client/react/core/queries";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { IRAFundingWrapper, Loader } from "gw-shared-components";

const IRAFundingContainer = () => {
    const { data: primaryPlanData, isLoading: isLoadingPrimaryPlan } = useGetPrimaryPlan();
    const translations = selectTranslations("iraFunding");

    const { gaId = "", indId = "", accuCode = "" } = primaryPlanData || {};

    if (!translations || isLoadingPrimaryPlan || !primaryPlanData) {
        return (
            <div data-testid="ira-contribution-component">
                <Loader fullscreen={true} messages={null} showMessages={false} useSvg={false} />
            </div>
        );
    }

    return (
        <div className="dashboard-ira-funding">
            <div data-testid="ira-contribution-component" role="main">
                <IRAFundingWrapper
                    accuCode={accuCode}
                    groupId={gaId}
                    indId={indId}
                    translations={translations}
                />
            </div>
        </div>
    );
};

export default IRAFundingContainer;
