import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { LoadingBar } from "gw-shared-components";

import { useAccountSummary, useInitData, useQuickViewAccounts } from "../../queries";
import { getPlanDetails } from "../quickView/helpers";
import type IQuickViewAccount from "../quickView/types";

import {
    LatestTransaction,
    QuickViewHeader,
    Balance,
    BalanceGraph,
    RateOfReturn
} from "./components";
import type { RetirementIncome, QuickViewTranslations } from "./types";

interface QuickViewProps {
    showInvestmentMix: boolean;
}
const DEFAULT_QUICK_VIEW_ACCOUNTS: IQuickViewAccount[] = [];

const QuickView = (props: QuickViewProps) => {
    const { showInvestmentMix } = props;
    const {
        data: quickViewData,
        isLoading: isQuickViewDataLoading,
        isError: isQuickViewError
    } = useQuickViewAccounts();

    const { data: accountSummary } = useAccountSummary();

    const { data: userData, isLoading: isUserLoading } = useInitData();

    // If there is an error fetching quick view data, return null
    if (isQuickViewError) {
        return null;
    }

    if (isQuickViewDataLoading || isUserLoading) {
        return (
            <div className="quickview-wrapper gray-border-000 rounded-border bg-white padding-100">
                <LoadingBar position="center" data-testid="loading-bar" />
            </div>
        );
    }

    const isQuickViewDataNullOrEmpty = !quickViewData || quickViewData.length === 0;
    // If there is no quick view data, return null
    if (isQuickViewDataNullOrEmpty) {
        return null;
    }

    const quickViewAccounts = quickViewData || DEFAULT_QUICK_VIEW_ACCOUNTS;
    const translations: QuickViewTranslations = selectTranslations("quickView");
    const retirementIncomeTranslations: RetirementIncome = selectTranslations("retirementIncome");

    // No need to pass planId as this QuickView card is only visible to users with a single account
    const planDetails = getPlanDetails(quickViewAccounts, null);

    return (
        <div className="quickview-wrapper gray-border-000 rounded-border bg-white">
            <div className="padding-100">
                <QuickViewHeader
                    planDetails={planDetails}
                    translations={translations}
                    showInvestmentMix={showInvestmentMix}
                    userData={userData?.data?.users}
                    retirementIncomeTranslations={retirementIncomeTranslations}
                />
                <Balance amount={accountSummary?.totalBalance} />
                <RateOfReturn planDetails={planDetails} translations={translations} />
                <BalanceGraph gaId={planDetails.gaId} translations={translations} />
                <LatestTransaction planDetails={planDetails} translations={translations} />
            </div>
        </div>
    );
};

export default QuickView;
