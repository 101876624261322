import { useQuery } from "@tanstack/react-query";

import getInitData from "../../services/getInitData";

//TODO: Consolidate with InitDataLiteResponse

type InvestmentMixOptions = {
    disableInvestmentMix: boolean;
    notAllMoneyTypeGroupingsFlow: boolean;
    portfolioManagementAllowed: boolean;
};

interface IrsLimitsRefData {
    "1165E": { [key: string]: number };
    "401A": { [key: string]: number };
    "401K": { [key: string]: number };
    "403B": { [key: string]: number };
    "457": { [key: string]: number };
    "457B": { [key: string]: number };
    HSA: Hsa;
    IRA: Ira;
}

interface Hsa {
    hsaFamily: number;
    hsaFamilyCatchUp: number;
    hsaSingle: number;
    hsaSingleCatchUp: number;
}

interface Ira {
    catchupEligibleAge: number;
    discountFactor: number;
    limit415: number;
    limitIRA: number;
    limitIRACatchup: number;
    limitIRANextYear: number;
    rateOfInflation: number;
}

interface BestNextSteps {
    hasDynamic: null;
    message: null;
    recommended: boolean;
    recommendedDollar: number;
    recommendedPct: number;
    staticMessage: null;
}

interface InvestmentOverview {
    advisoryServicesOptions: AdvisoryServicesOptions;
    individualOptions: IndividualOptions;
    isRebalancerAllowed: null;
    managedAccountsOptions: ManagedAccountsOptions;
    moneyTypeGroupings: unknown[];
    noEquityMixData: boolean;
    notifications: null;
    partHasZeroBalance: boolean;
    partLevelMoneyTypeGroupings: boolean;
    status: string;
}

interface AdvisoryServicesOptions {
    allowRiskBasedFunds: boolean;
    allowTargetDateFunds: boolean;
    assetModelsAllowed: boolean;
    currentAllocations: CurrentAllocations;
    isCurrentInRiskBasedFund: boolean;
    isCurrentInTargetDateFund: boolean;
}

interface CurrentAllocations {
    aggregatedEquityRatio: number;
    aggregatedStockRatio: number;
    asOfDate: null;
    cdscCharge: null;
    cmc: null;
    errorMessage: null;
    investmentOptions: InvestmentOption[];
    vac: null;
}

interface InvestmentOption {
    allocationPercentage: number;
    allocationPercentageDecimal: number;
    alongsideAssetModelInd: null;
    alongsideNonModelInd: boolean;
    amount: null;
    assetClass: string;
    assetClassDisplayOrder: null;
    assetClassificationDisplayOrder: null;
    authorizedForStock: null;
    available: boolean;
    bondFundMix: number;
    containsStock: null;
    cusip: null;
    displayOrder: string;
    equityFundMix: number;
    fullCurrentAllocation: null;
    fundTypeCode: string;
    gaamModelDesc: string;
    gaamModelNbr: null;
    glwbFund: null;
    groupingCode: string;
    guaranteedBenefitFundType: null;
    id: ID;
    incomeFlexFund: null;
    investmentOptionRatingData: null;
    investmentRatingData: null;
    ivaId: null;
    maxAllocPercent: null;
    name: string;
    overviewUrl: null;
    prospectusUrl: null;
    recommended: boolean;
    sddetyCode: null;
    sdteLength: null;
    sdteLengthQual: string;
    suppressPortfolioSelection: null;
    targetYear: string;
    tickerSymbol: null;
}

interface ID {
    gaioQual: number;
    sdioId: string;
}

interface IndividualOptions {
    allowAllocationsUpdate: boolean;
    currentAllocationModel: boolean;
    disableInvestmentMix: boolean;
    doMoneyTypeGroupingsApply: boolean;
    doTradeRestrictionsApply: null;
    hasAllMoneyTypeGroup: boolean;
    isPortfolioManagementAllowed: boolean;
    modelName: null;
}

interface ManagedAccountsOptions {
    advisoryServiceProviderCode: string;
    advisoryServicesProgramName: string;
    disclosureText: string;
    disclosureUrl: string;
    eligibleForAdvice: boolean;
    eligibleForManagedAccounts: boolean;
    enrolledInAMA: boolean;
    enrolledInAdvice: boolean;
    enrolledInFullManagedAccounts: boolean;
    enrolledInGuidance: boolean;
    fundLineupStatus: string;
    groupServiceRules: GroupServiceRules;
    investmentAdvisor: string;
    managedAccountsProgramName: string;
    menuOptions: MenuOptions;
    onlineAdviceProgramName: string;
    onlineGuidanceProgramName: null;
    privacyUrl: string;
    retailContextEnrolledInMTR: boolean;
    termsOfServiceUrl: string;
}

interface GroupServiceRules {
    allowAdvice: boolean;
    allowEnhancedNextGenAdvisoryServices: boolean;
    allowFullManagedAccounts: boolean;
    allowGuidance: boolean;
    allowNextGenAdvisoryServices: boolean;
}

interface MenuOptions {
    FE: boolean;
    IBBOTSON: boolean;
}

interface SocialSecuritySummary {
    pcapPersonId: string;
    socialSecurityIncome: SocialSecurityIncome;
    ssBenefitByStartAge: { [key: string]: number };
}

interface SocialSecurityIncome {
    annualAmountPreference: string;
    socialSecurityAmount: null;
    socialSecurityStartAge: number;
}

interface ProjectionsMap {
    a: number;
    c: number;
}

type RangeKey = `${number}~${number}~${number}~${number}`;

export interface User {
    age: number;
    clientId: string;
    dateOfBirth: number;
    dateOfBirthSource: string;
    defaultIncomeGoalPct: number;
    defaultLEAge: number;
    firstName: string;
    gender: string;
    headOfHousehold: boolean;
    incomeGoalTerm: number;
    incomeGoalUnits: string;
    incomeGoalValue: number;
    individualId: string;
    isBeneOrTCP: boolean;
    lastName: string;
    middleName: string;
    name: string;
    nycGoalData: NycGoalData;
    pcapPersonId: string;
    personId: number;
    plans: Plan[];
    projectionsMap: Record<RangeKey, ProjectionsMap>;
    socialSecuritySummary: SocialSecuritySummary;
    type: string;
}

interface Plan {
    allowableDeferralsWithRules: AllowableDeferralsWithRules;
    balances: Balance[];
    companyMatchRules: CompanyMatchRule[];
    deferrals: Deferral[];
    higherCULimitInd: boolean;
    id: string;
    irsCode: string;
    irsLimits: { [key: string]: number };
    limits: Limits;
    payFrequency: string;
    planLvlNycGoalFlag: string;
    planName: string;
    primary: boolean;
    retirementOption: RetirementOption;
    rules: { [key: string]: boolean };
    salary: Salary;
    terminated: boolean;
    totalSvcYrs: number;
}

interface AllowableDeferralsWithRules {
    allowableDeferrals: AllowableDeferral[];
    catchUp402gInd: string;
    combinedRules: CombinedRule[];
    tierRules: unknown[];
}

interface AllowableDeferral {
    ageCatchupApplicable: string;
    allowAgeCombinedInd: string;
    allowFutureScheduleOption: string;
    allowOneTimeFutureDeferralOption: string;
    allowOngoingFutureDeferralOption: string;
    allowScheduleInd: string;
    configId: number;
    defrlAvailCode: string;
    displayName: string;
    displayOrder: number;
    enrollmentGroupCode: string;
    futureDatedDefrlOnetimeMessage: null;
    futureDatedDefrlOnetimeMessageCode: null;
    futureDatedDefrlOngoingMessage: null;
    futureDatedDefrlOngoingMessageCode: null;
    futureDatedDefrlScheduledMessage: null | string;
    futureDatedDefrlScheduledMessageCode: null | string;
    gdmtSeqnbr: number;
    granularityAmt: number;
    granularityPct: null;
    incomeStream: string;
    mandatoryDeferralInd: string;
    maxAmt: number;
    maxNextYearAmt: number;
    maxPct: number;
    maximizeEligibleInd: null;
    minAmt: number;
    minPct: number;
    minRequiredInd: string;
    nextIncrDateOverrideInd: string;
    nextPayRollDate: string;
    pctAmtCode: string;
    schedIncrMaxOverrideInd: string;
    sdmtCode: string;
    submissionDate: string;
    suspensionReinstateDate: null;
    taxStatus: string;
    typeCode: string;
}

interface CombinedRule {
    deferralTypeCodes: string[];
    defrlGrpCode: string;
    maxAmt: number;
    maxPct: null;
    messages: Message[];
    minAmt: number;
    minPct: null;
}

interface Message {
    message: string;
    msgCode: string;
    msgType: string;
}

interface Balance {
    moneyType: string;
    taxStatus: string;
    totalBalance: number;
    vestedBalance: number;
    vestedPercent: number;
}

interface CompanyMatchRule {
    highThresholdDollar: number;
    highThresholdPercent: number;
    lowThresholdDollar: number;
    lowThresholdPercent: number;
    matchPercent: number;
    maxMatchDollar: number;
    maxMatchPercent: number;
    maxYrOfService: number;
    minYrOfService: number;
    ruleDesc: string;
    ruleType: string;
    source: string;
    tierCd: string;
}

interface Deferral {
    active: boolean;
    config: Config;
    csor: boolean;
    current: boolean;
    defaulted: boolean;
    deferralTypeCode: string;
    effDate: string;
    nextPayRollDate: string;
    payrollOption: string;
    pctAmtCode: string;
    specialCatchup: boolean;
    statusCode: string;
    submissionDate: string;
    value: number;
}

interface Config {
    ageCatchupApplicable: string;
    allowAgeCombinedInd: string;
    allowScheduleInd: string;
    defrlAvailCode: string;
    displayName: string;
    displayOrder: number;
    enrollmentGroupCode: string;
    granularity: number;
    incomeStream: string;
    maxDeferral: number;
    maxSlider: number;
    maximizeEligibleInd: null;
    minDeferral: number;
    minRequiredInd: string;
    taxStatus: string;
    type: string;
}

interface Limits {
    ageCatchUpMethodCode: string;
    ageCatchupAllowed: boolean;
}

interface RetirementOption {
    confidence: number;
    equityPct: number;
    inclSocialSec: boolean;
    retireAge: number;
}

interface Salary {
    base: number;
    nqContributionPct: null;
    salUpdReminder: boolean;
    salaryOnFile: number;
    source: string;
    total: number;
    variable: number;
}

interface NycGoalData {
    planEnabled: boolean;
    pourOverEnabled: boolean;
}
interface Owner {
    pcapPersonId: string;
    percentage: number;
    role: string;
}

type IntegratedAsset = {
    accountType: string;
    accountTypeGroup: "RETIREMENT" | "OTHER_GROUP";
    accountTypeSubtype: string;
    aggregationErrorType: "NO_ERROR" | "OTHER_ERROR_TYPE";
    aggregationStatus: "AGGREGATED" | "OTHER_STATUS";
    allocationSet: {
        alternatives: number;
        cash: number;
        intlBonds: number;
        intlStocks: number;
        unclassified: number;
        usBonds: number;
        usStocks: number;
    };
    createdDate: string;
    currentBalance: number;
    custodianAccountNumber: string;
    firmName: string;
    isExternal: boolean;
    isManual: boolean;
    name: string;
    owners: Owner[];
    pcapAccountId: string;
    pcapUserId: string;
    productName: string;
    productType: "INVESTMENT" | "OTHER_PRODUCT_TYPE";
    siteName: string;
    updatedDate: string;
};

type IntegratedGoal = {
    additionalAttributes: unknown | null;
    annualAmount: number;
    annualAmountPreference: string | null;
    duration: number | null;
    forever: boolean;
    goalCategoryKey: string;
    goalDescription: string;
    goalType: string;
    isNotCOLA: boolean;
    isPreTax: boolean;
    pcapPersonId: string | null;
    startAge: number;
};

type GetInitData = {
    data: {
        asOfDate: string;
        bestNextSteps: BestNextSteps;
        integratedAssets: IntegratedAsset[];
        integratedGoals: IntegratedGoal[];
        investmentMixOptions: InvestmentMixOptions;
        investmentOverview: InvestmentOverview;
        irsLimitsRefData: IrsLimitsRefData;
        migratedContext: string;
        users: User[];
    };
};

const useInitData = () => {
    return useQuery<GetInitData>({
        queryKey: ["init-data"],
        queryFn: () => getInitData(),
        enabled:
            globalThis.integratedEligibility &&
            globalThis?.integratedEligibility?.eligibilityLoaded &&
            globalThis?.integratedEligibility?.showLIAT
    });
};

export default useInitData;
