import { hasPendingChanges, shouldShowReviewButton } from "../../business/retirementIncome";

import { SET_SHOW_TRADE_OFFS, SET_SHOW_REVIEW_BUTTON } from "./reviewResetTypes";

/**
 * Determine if there are changes in slider or retirement age
 * @param {}
 */
export const getShowTradeoffs = (
    prevStateTradeOff,
    deferrals,
    originalRetireAge,
    currentRetireAge,
    individualOptions,
    originalEquity,
    currentEquityMix,
    investmentWarnings,
    dispatch
) => {
    const hasChanges = hasPendingChanges(
        true,
        true,
        deferrals,
        originalRetireAge,
        currentRetireAge,
        individualOptions,
        originalEquity,
        currentEquityMix,
        investmentWarnings
    );
    if (prevStateTradeOff !== hasChanges) {
        dispatch(setShowTradeOffs(hasChanges));
    }
};

/**
 * Determine if there are pending changes or investment warning
 * @param {*} prevState
 */
export const showReviewButton = (
    prevState,
    deferrals,
    originalRetireAge,
    currentRetireAge,
    individualOptions,
    originalEquity,
    currentEquityMix,
    investmentWarnings,
    dispatch
) => {
    const showReviewBtn = shouldShowReviewButton(
        deferrals,
        originalRetireAge,
        currentRetireAge,
        individualOptions,
        originalEquity,
        currentEquityMix,
        investmentWarnings
    );
    if (prevState !== showReviewBtn) {
        dispatch(setShowReviewButton(showReviewBtn));
    }
};

export const setShowReviewButton = (payload) => {
    return {
        type: SET_SHOW_REVIEW_BUTTON,
        payload
    };
};

export const setShowTradeOffs = (payload) => {
    return {
        type: SET_SHOW_TRADE_OFFS,
        payload
    };
};
