import { isUndefinedOrNull } from "gw-shared-components";

/**
 * @function calculateAvailableWidth
 * @param {number} totalAvailable
 * @param {number} trackWidth
 * @param {number} thumbWidth
 * @returns {number}
 * @description Get the Avaialbe track width. Percentage avaiable * total track width + half the thumb width
 **/
export const calculateAvailableWidth = (totalAvailable, trackWidth, thumbWidth) => {
    return (totalAvailable / 100) * trackWidth + thumbWidth / 2;
};

/**
 * @function getThumbPercentageOfTrack
 * @param {number} trackWidth
 * @param {number} thumbWidth
 * @returns {number}
 * @description Find what percent of the slider track is available. Should be ~14-15
 **/
export const getThumbPercentageOfTrack = (trackWidth, thumbWidth) => {
    return (thumbWidth / trackWidth) * 100;
};

/**
 * @function totalTrackAvailable
 * @param {number} trackWidth
 * @param {number} thumbWidth
 * @returns {number}
 * @description Track sub thumb width divide by total track width
 **/
export const totalTrackAvailable = (trackWidth, thumbWidth) => {
    return ((trackWidth - thumbWidth) / trackWidth) * 100;
};

/**
 * @function convertPixelToPercent
 * @param {number} value
 * @param {number} trackWidth
 * @description Converts pixel value to percent
 */
export const convertPixelToPercent = (value, trackWidth) => {
    return `${(value / trackWidth) * 100}%`;
};

/**
 * @function showEstimated
 * @param {object} deferral
 * @param {number} deferralsLength
 * @description Update variable to tell the sliders how and where to display the word "ESTIMATED"
 * @return {object} storing two booleans
 */
export const showEstimated = (deferral, deferralsLength) => {
    //
    let singleEstimatedLink = false;
    let showEstimated = false;

    if (isUndefinedOrNull(deferral) || isUndefinedOrNull(deferral))
        return { singleEstimatedLink, showEstimated };

    if (deferral.csor && deferralsLength > 1) {
        showEstimated = true;
    } else if (deferral.csor && deferralsLength === 1) {
        singleEstimatedLink = true;
    }

    return { singleEstimatedLink, showEstimated };
};
