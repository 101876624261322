import React from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { resetSliderValues, saveChanges } from "../../middleware/reviewChangesMiddleware";

const HighContributionModal = ({
    showHighContributionModal,
    setShowHighContributionModal,
    setShowConfirmationModal,
    deferralClass
}) => {
    const dispatch = useDispatch();
    const app = selectTranslations("app");
    const reviewChangesTranslations = selectTranslations("reviewChanges");
    const cancelHighContributionModal = (event) => {
        event.preventDefault();
        dispatch(resetSliderValues());
        setShowHighContributionModal(false);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: "cancel_changes_button"
        });
    };
    const applyHighContributionChange = (event) => {
        event.preventDefault();
        setShowHighContributionModal(false);
        setShowConfirmationModal(true);
        dispatch(saveChanges(deferralClass));
    };
    return (
        <>
            <Modal
                dialogClassName="high-contribution-dialog"
                show={showHighContributionModal}
                onHide={cancelHighContributionModal}
            >
                <div className="high-contribution-container">
                    <Modal.Header className="modal-header">
                        <h2>{app.deferrals.contributionReminder.header}</h2>
                        <div
                            className="alert alert-warning"
                            data-testid="high-contributions-warning"
                        >
                            <p>{reviewChangesTranslations.warnings.highContributions}</p>
                        </div>
                    </Modal.Header>

                    <div className="modal-footer">
                        <div className="footer-content">
                            <div className="btns">
                                <div className="cancel-btn">
                                    <button
                                        type="button"
                                        data-dismiss="modal"
                                        onClick={(event) => cancelHighContributionModal(event)}
                                    >
                                        {app.common.labels.cancel}
                                    </button>
                                </div>

                                <div className="submit-btn">
                                    <button
                                        type="button"
                                        id="review-changes-btn"
                                        onClick={(event) => applyHighContributionChange(event)}
                                    >
                                        {app.deferrals.contributionReminder.applyChange}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
HighContributionModal.propTypes = {
    showHighContributionModal: PropTypes.bool,
    setShowHighContributionModal: PropTypes.func,
    setShowConfirmationModal: PropTypes.func,
    deferralClass: PropTypes.object
};
export default HighContributionModal;
