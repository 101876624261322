import type IQuickViewAccount from "../../../quickView/types";
import type { QuickViewTranslations, ILatestTransaction } from "../../types";

type GetLatestTransactionsArgs = {
    quickViewData?: IQuickViewAccount;
    type: QuickViewTranslations["txnType"];
};

const getLatestTransactions = ({ quickViewData, type }: GetLatestTransactionsArgs) => {
    if (!quickViewData?.lastTxnHistory) {
        return [];
    }

    const { lastTxnHistory } = quickViewData;

    const latestTransactions: ILatestTransaction[] = [];

    if (lastTxnHistory.category === "C") {
        if (lastTxnHistory.eeAmount) {
            latestTransactions.push({
                name: type.myContribution,
                date: lastTxnHistory.effDate,
                amount: lastTxnHistory.eeAmount
            });
        }

        if (lastTxnHistory.erAmount) {
            latestTransactions.push({
                name: type.employerContribution,
                date: lastTxnHistory.effDate,
                amount: lastTxnHistory.erAmount
            });
        }
    } else {
        latestTransactions.push({
            name: lastTxnHistory.txnDesc,
            date: lastTxnHistory.effDate,
            amount: lastTxnHistory.amount
        });
    }

    return latestTransactions;
};

export default getLatestTransactions;
