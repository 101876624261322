import React, { useMemo } from "react";

import type { User } from "client/src/app/liat/queries/useInitData/useInitData";

import getEquityPctLabel from "../../helpers/getEquityPctLabel/getEquityPctLabel";
import type { RetirementIncome, Tier } from "../../types";

interface InvestmentMixProps {
    retirementIncomeTranslations: RetirementIncome;
    showInvestmentMix: boolean;
    userData: User[] | undefined;
}

const InvestmentMix = React.memo(
    ({ showInvestmentMix, retirementIncomeTranslations, userData }: InvestmentMixProps) => {
        const equityPct = userData?.[0]?.plans?.[0]?.retirementOption?.equityPct;
        const equityPctRanges: Tier[] = useMemo(
            () => retirementIncomeTranslations?.sliders?.["rivd-investmentStyle"]?.tiers || [],
            [retirementIncomeTranslations]
        );

        const equityPctLabel = useMemo(
            () => getEquityPctLabel({ equityPct, equityPctRanges }),
            [equityPct, equityPctRanges]
        );

        if (!equityPct || !showInvestmentMix) {
            return null;
        }

        return (
            <div className="alert-wrapper">
                <p className="alert-label">{equityPctLabel}</p>
            </div>
        );
    }
);

InvestmentMix.displayName = "InvestmentMix";
export default InvestmentMix;
