import DateUtil from "core-ui/client/src/app/DateUtil";

import { type GetHistoriesParams } from "../../../queries/useGetHistories/useGetHistories";

export const DATE_FORMAT = "YYYY-MM-DD";

const getBalanceGraphHistories = (userAccountIds: string): GetHistoriesParams => {
    const currentDate = DateUtil.getDate();
    const previousDate = DateUtil.subtractFromDate(90, "day", currentDate);

    return {
        userAccountIds,
        startDate: DateUtil.getDateFormatted(DATE_FORMAT, previousDate) as string,
        endDate: DateUtil.getDateFormatted(DATE_FORMAT, currentDate) as string,
        intervalType: "DAY",
        types: JSON.stringify(["balances"])
    };
};

export default getBalanceGraphHistories;
