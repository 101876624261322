import type IGetAccountsData from "../../../queries/useGetAccounts2/IGetAccountsData";
import { type IAccount } from "../../../queries/useGetAccounts2/IGetAccountsData";

type IGetPrimaryAccount = (gaIS: string, accounts2Data?: IGetAccountsData) => IAccount | undefined;

const getPrimaryAccount: IGetPrimaryAccount = (gaId, accounts2Data) => {
    const accounts = accounts2Data?.spData?.accounts || [];
    if (accounts.length < 1) return;
    const { participantContext } = accounts2Data!.spHeader;
    return accounts.find(({ empowerAttributes }) => {
        const { planId, contextId } = empowerAttributes || {};
        if (contextId && planId) {
            return contextId === participantContext.indId && planId.startsWith(gaId);
        }
    });
};

export default getPrimaryAccount;
