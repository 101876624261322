import React, { memo, Suspense } from "react";

import { useQuery } from "@tanstack/react-query";
import EventBus from "core-ui/client/src/app/core/eventbus/EventBus";
import { Switch, Route, Redirect } from "react-router-dom";

import {
    ROUTE_FAQ,
    ROUTE_MAX_DASHBOARD,
    ROUTE_ADVICE_DASHBOARD,
    ROUTE_NETWORTH,
    ROUTE_TRANSACTIONS,
    ROUTE_PORTFOLIO,
    ROUTE_RETIREMENT_PLANNER,
    ROUTE_SAVINGS_PLANNER,
    ROUTE_MANAGED_ACCOUNTS,
    ROUTE_INVESTMENT_CHECKUP,
    ROUTE_ENROLL,
    ROUTE_SETTINGS,
    ROUTE_CASHFLOW,
    ROUTE_DASHBOARD,
    RETIREMENT_INCOME_ROUTE,
    ROUTE_CLOSED_ACCOUNTS,
    ROUTE_ACCOUNTS_ADD,
    ROUTE_MANUAL_CLASSIFICATIONS,
    ROUTE_STOCK_OPTIONS,
    ROUTE_EXCLUDE_ACCOUNTS,
    ROUTE_OPEN_ACCOUNT,
    ROUTE_OPEN_ACCOUNT_PS,
    ROUTE_OPEN_ACCOUNT_IRA,
    ROUTE_EDUCATION,
    ROUTE_APPT,
    ROUTE_GOALS,
    ROUTE_ACCOUNTS_DETAILS,
    ROUTE_NOTIFICATIONS,
    ROUTE_INVESTMENT_LINEUP,
    ROUTE_TRANSFER_FUNDS,
    ROUTE_DOCUMENTS,
    ROUTE_MANAGE_BENEFICIARIES,
    ROUTE_VIEW_MEETING,
    ROUTE_MANAGE_INVESTMENTS,
    ROUTE_ADVISOR,
    ROUTE_EMERGENCY_FUND_ACCOUNTS,
    ROUTE_RECURRING_TRANSFERS,
    ROUTE_GMWB,
    ROUTE_RETIREMENT_INCOME_STRATEGY,
    ROUTE_MANAGED_ACCOUNTS_NEW_MARKETING,
    ROUTE_MANAGED_ACCOUNTS_NEW,
    ROUTE_ONLINE_ADVICE_NEW,
    ROUTE_LEAVE_ROUTE,
    ROUTE_ACCOUNT_ACTIONS,
    ROUTE_OPEN_BANK_ACCOUNT,
    ROUTE_OPEN_BANK_ACCOUNT_V2,
    ROUTE_CLOSE_BANK_ACCOUNT,
    ROUTE_REFERRAL,
    ROUTE_TRANSFER_MANAGER,
    ROUTE_OPEN_ACCOUNT_OPTIONS,
    ROUTE_MARKETING_CONSENT,
    ROUTE_FP_TOPICS,
    ROUTE_PLANNING_HISTORY,
    ROUTE_MANAGE_BENEFICIARIES_PS,
    ROUTE_PERSONAL_STRATEGY,
    ROUTE_PERSONAL_STRATEGY_TRADES,
    ROUTE_PERSONAL_STRATEGY_DIVIDENDS,
    ROUTE_PERSONAL_STRATEGY_RECOMMENDATION,
    ROUTE_STRATEGY_REVIEW_WIZARD,
    ROUTE_EMPLOYER_PLAN_ANALYSIS,
    ROUTE_INSURANCE_WIZARD,
    ROUTE_SOCIAL_SECURITY_WIZARD,
    ROUTE_EQUITY_COMPENSATION_WIZARD,
    ROUTE_PERSONAL_STRATEGY_PLUS,
    ROUTE_PERSONAL_STRATEGY_PLUS_INTRO,
    ROUTE_PERSONAL_STRATEGY_PLUS_OWNER_INFO,
    ROUTE_PERSONAL_STRATEGY_PLUS_LINK_PAYMENT,
    ROUTE_PERSONAL_STRATEGY_PLUS_ABOUT,
    ROUTE_DEBT_REVIEW_WIZARD,
    ROUTE_EMERGENCY_FUND_WIZARD,
    ROUTE_RECONNECT_RETIREMENT_ACCOUNT,
    ROUTE_UPDATE_FORWARDING_CONTACT_INFORMATION,
    ROUTE_SAVINGS_STRATEGY_WIZARD,
    ROUTE_REQUEST_ADVICE_WIZARD,
    ROUTE_NEXT_BEST_ACTIONS,
    ROUTE_ROLLOVER,
    ROUTE_RETIREMENT_FEE_ANALYZER,
    ROUTE_FAST_LINK,
    ROUTE_EDIT_ACCOUNT,
    ROUTE_IRA_ACCOUNT_FUNDING,
    ROUTE_IRA_FUNDING,
    ROUTE_TAX_FILING,
    ROUTE_TAX_FILING_VENDOR,
    ROUTE_TAX_FILING_VENDOR_CONTINUE,
    ROUTE_TAX_FILING_JUMP_SCREEN,
    ROUTE_TAX_FILING_STATUS
} from "../routes";

import IntegratedContainer from "./containers/IntegratedContainer";
import IRAFundingContainer from "./containers/IRAFundingContainer";
import initAnalytics from "./events/initAnalytics";
import handlePcapBundle from "./services/pcap/handlePcapBundle";
import checkDeminWithdrawalLanding from "./utils/checkDeminWithdrawalLanding";

const LazyFaq = React.lazy(() => import("./components/faq/Faq"));

const LazyMaxComponent = React.lazy(() => import("./components/MaxComponent"));

const DASHBOARD_ROUTES = [
    ROUTE_NETWORTH,
    ROUTE_TRANSACTIONS,
    ROUTE_CASHFLOW,
    ROUTE_PORTFOLIO,
    ROUTE_INVESTMENT_CHECKUP,
    ROUTE_ENROLL,
    ROUTE_RETIREMENT_PLANNER,
    ROUTE_SAVINGS_PLANNER,
    ROUTE_MANAGED_ACCOUNTS,
    ROUTE_SETTINGS,
    ROUTE_CLOSED_ACCOUNTS,
    ROUTE_ACCOUNTS_ADD,
    ROUTE_MANUAL_CLASSIFICATIONS,
    ROUTE_STOCK_OPTIONS,
    ROUTE_EXCLUDE_ACCOUNTS,
    ROUTE_OPEN_ACCOUNT,
    ROUTE_OPEN_ACCOUNT_PS,
    ROUTE_OPEN_ACCOUNT_IRA,
    ROUTE_EDUCATION,
    ROUTE_APPT,
    ROUTE_GOALS,
    ROUTE_ACCOUNTS_DETAILS,
    ROUTE_NOTIFICATIONS,
    ROUTE_DASHBOARD,
    ROUTE_INVESTMENT_LINEUP,
    ROUTE_TRANSFER_FUNDS,
    ROUTE_DOCUMENTS,
    ROUTE_MANAGE_BENEFICIARIES,
    ROUTE_VIEW_MEETING,
    ROUTE_MANAGE_INVESTMENTS,
    ROUTE_ADVISOR,
    ROUTE_EMERGENCY_FUND_ACCOUNTS,
    ROUTE_RECURRING_TRANSFERS,
    ROUTE_GMWB,
    ROUTE_RETIREMENT_INCOME_STRATEGY,
    ROUTE_MANAGED_ACCOUNTS_NEW,
    ROUTE_MANAGED_ACCOUNTS_NEW_MARKETING,
    ROUTE_ONLINE_ADVICE_NEW,
    ROUTE_LEAVE_ROUTE,
    ROUTE_ACCOUNT_ACTIONS,
    ROUTE_OPEN_BANK_ACCOUNT,
    ROUTE_OPEN_BANK_ACCOUNT_V2,
    ROUTE_CLOSE_BANK_ACCOUNT,
    ROUTE_REFERRAL,
    ROUTE_TRANSFER_MANAGER,
    ROUTE_OPEN_ACCOUNT_OPTIONS,
    ROUTE_MARKETING_CONSENT,
    ROUTE_FP_TOPICS,
    ROUTE_PLANNING_HISTORY,
    ROUTE_MANAGE_BENEFICIARIES_PS,
    ROUTE_PERSONAL_STRATEGY,
    ROUTE_PERSONAL_STRATEGY_TRADES,
    ROUTE_PERSONAL_STRATEGY_DIVIDENDS,
    ROUTE_PERSONAL_STRATEGY_RECOMMENDATION,
    ROUTE_STRATEGY_REVIEW_WIZARD,
    ROUTE_EMPLOYER_PLAN_ANALYSIS,
    ROUTE_INSURANCE_WIZARD,
    ROUTE_SOCIAL_SECURITY_WIZARD,
    ROUTE_EQUITY_COMPENSATION_WIZARD,
    ROUTE_PERSONAL_STRATEGY_PLUS,
    ROUTE_PERSONAL_STRATEGY_PLUS_INTRO,
    ROUTE_PERSONAL_STRATEGY_PLUS_OWNER_INFO,
    ROUTE_PERSONAL_STRATEGY_PLUS_LINK_PAYMENT,
    ROUTE_PERSONAL_STRATEGY_PLUS_ABOUT,
    ROUTE_DEBT_REVIEW_WIZARD,
    ROUTE_EMERGENCY_FUND_WIZARD,
    ROUTE_RECONNECT_RETIREMENT_ACCOUNT,
    ROUTE_UPDATE_FORWARDING_CONTACT_INFORMATION,
    ROUTE_SAVINGS_STRATEGY_WIZARD,
    ROUTE_REQUEST_ADVICE_WIZARD,
    ROUTE_NEXT_BEST_ACTIONS,
    ROUTE_ROLLOVER,
    ROUTE_RETIREMENT_FEE_ANALYZER,
    ROUTE_FAST_LINK,
    ROUTE_EDIT_ACCOUNT,
    ROUTE_IRA_ACCOUNT_FUNDING,
    ROUTE_TAX_FILING,
    ROUTE_TAX_FILING_VENDOR,
    ROUTE_TAX_FILING_VENDOR_CONTINUE,
    ROUTE_TAX_FILING_JUMP_SCREEN,
    ROUTE_TAX_FILING_STATUS
];

const REDIRECT_ROUTE = RETIREMENT_INCOME_ROUTE;

const TEN_MINUTES_IN_MILLISECONDS = 10 * 60 * 1000;

const Application = () => {
    const eventBus = new EventBus(window, document);

    // Expose event bus to pcap
    window.dashboardUtils = { eventBus };

    // TODO(EOC): Decide if we want to use the data returned from service
    // or continue setting it on the global integratedEligilibty object.
    const { data: eligibilityData, isLoading: loadingEligibility } = useQuery({
        queryKey: ["eligibility"],
        queryFn: async () => {
            const { data } = await handlePcapBundle();
            if (data instanceof Error) {
                console.error("Error fetching eligibility data", data);
            }

            // Initialize analytics for Amplitude and Sentry events
            initAnalytics();
            checkDeminWithdrawalLanding();

            return data.data;
        },
        // calling saml2 more than once causes the PCAP API calls to fail
        enabled:
            !globalThis.integratedEligibility.eligibilityLoaded ||
            !globalThis.integratedEligibility.samlToken, // should only need to call this service once
        staleTime: TEN_MINUTES_IN_MILLISECONDS, // the data will be considered fresh and cached for 10 minutes
        networkMode: "offlineFirst" // Query will run the queryFn once, but then pause retries
    });

    return (
        <>
            <Switch>
                <Route
                    path={DASHBOARD_ROUTES}
                    render={() => (
                        <Suspense>
                            <IntegratedContainer
                                eligibilityData={eligibilityData}
                                loadingEligibility={loadingEligibility}
                            />
                        </Suspense>
                    )}
                />
                <Route
                    path={ROUTE_FAQ}
                    render={() => (
                        <Suspense>
                            <LazyFaq />
                        </Suspense>
                    )}
                    exact
                />
                <Route
                    path={ROUTE_IRA_FUNDING}
                    render={() => (
                        <Suspense>
                            <IRAFundingContainer />
                        </Suspense>
                    )}
                />

                <Route
                    path={[ROUTE_MAX_DASHBOARD, ROUTE_ADVICE_DASHBOARD]}
                    render={(routeProps) => (
                        <LazyMaxComponent {...routeProps} eventBus={eventBus} />
                    )}
                />

                <Redirect to={REDIRECT_ROUTE} />
            </Switch>
        </>
    );
};

export default memo(Application);
