import React, { useState } from "react";

import { dispatchAmplitude, AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { Loader, ObjectUtil, Print } from "gw-shared-components";
import PropTypes from "prop-types";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { useSelector, useDispatch } from "react-redux";

import { setShowConfirmModal } from "../../actions/confirmChanges/confirmChangesAction";
import { setShowReviewButton } from "../../actions/reviewReset/reviewResetActions";
import { dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import { canChangeEquityMix } from "../../business/investments";
import {
    getMaximizer,
    launchEquityMix,
    hasEquityMixChanged,
    equityMixChangeOnly
} from "../../business/retirementIncome";
import DeferralConstants from "../../constants/DeferralConstants";
import ReferenceData from "../../constants/ReferenceData";
import {
    confirmDone,
    resetSliderValues,
    resetContributionSlidersOnly
} from "../../middleware/reviewChangesMiddleware";
import { reselectReviewLastChanges } from "../../selectors/reselectSelectors";

import { ConfirmEvents } from "./events/ConfirmChangesEvents";

const AFTERTAX_CODES = ["AFTER", "ABONUS", "AFTVAR", "AFTVR2", "AFTMAN"];

const ConfirmChanges = ({
    showConfirmModal,
    setShowConfirmationModal,
    showWarningsOnlyModal,
    setShowWarningsOnlyModal,
    showHighContributionModal,
    deferralClass,
    isHighContribution,
    retirementAgeChanged
}) => {
    const dispatch = useDispatch();
    const confirmModalId = useSelector((state) => state.reviewChanges.confirmModalId);
    const equityMixWarnings = useSelector((state) => state.investments.equityMixWarnings);
    const { priorRetirementAge, priorEquityMix } = useSelector(reselectReviewLastChanges);

    const equityMix = useSelector((state) => state.investments.equityMix);
    const retireAge = useSelector((state) => state.participant.retirementAge);
    const deferralList = useSelector((state) => state.participant.deferrals);
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const deferralSetupConfig = useSelector((state) => state.deferralSetupConfig);
    const confirmationData = useSelector((state) => state.reviewChanges.confirmationData);
    const individualOptions = useSelector((state) => state.investments.individualOptions);
    const indivId = useSelector((state) => state.participant.individualId);
    const deferralSubmissionWarnings = useSelector(
        (state) => state.deferralSetupConfig.deferralSubmissionWarnings
    );
    const reviewChangesTranslations = selectTranslations("reviewChanges");
    const retirementIncomeTranslations = selectTranslations("retirementIncome");

    const deferralWarnings = useSelector((state) => state.deferralSetupConfig.deferralWarnings);
    const planId = primaryPlan.id;

    const [showInfo, setShowInfo] = useState(false);
    const deferralWarningsHasLength = () => deferralWarnings && deferralWarnings.length > 0;

    // Values that review changes page required.
    const title = selectTranslations("title");
    const confirmChangesText = selectTranslations("confirmChanges");
    const { title: confirmModalTitle, contributionRate, to } = confirmChangesText;

    const app = selectTranslations("app");
    const maximizer = selectTranslations("maximizer");
    const reviewChanges = selectTranslations("reviewChanges");
    const effective = reviewChanges.effective;

    const printLbl = app.common.labels.print;
    const resetValues = (...args) => dispatch(confirmDone(...args));

    const titleReplace = {
        class: "confirm-title",
        content: `${title} - ${confirmModalTitle}`
    };

    const inPlanRothConversionInd = primaryPlan.planRules.inPlanRothConversionInd;

    const modelDeferralsChanged =
        deferralClass.getChangedDeferrals("model")?.length > 0 ||
        deferralClass.getChangedDeferrals("grandfathered")?.length > 0;
    const fullDeferralsChanged = deferralClass.getChangedDeferrals("full")?.length > 0;
    // Functions that review changes page needed.
    const hasContributionRateChanges = (ignoreWarnings) => {
        if (
            !deferralClass.canChangeDeferrals() ||
            (!ignoreWarnings &&
                deferralClass.getHasDeferralWarnings(deferralWarnings, deferralSubmissionWarnings))
        ) {
            return false;
        }

        const changed =
            deferralClass.getChangedDeferrals(DeferralConstants.category.FULL)?.length > 0;
        return changed;
    };

    const requestRA = () => {
        if (hasContributionRateChanges()) {
            return getRetirementAgeDelta() > 0 ? "andIncreaseRA" : "andDecreaseRA";
        } else {
            return getRetirementAgeDelta() > 0 ? "noChangeCRIncreaseRA" : "noChangeCRDecreaseRA";
        }
    };

    const requestCR = (isFirst, deferral) => {
        if (isFirst) {
            return deferral.value > deferral.priorValue ? "requestIncreaseCR" : "requestDecreaseCR";
        } else {
            return deferral.value > deferral.priorValue
                ? "requestIncreaseNext"
                : "requestDecreaseNext";
        }
    };

    const getDecimals = (value) => {
        if (value % 1) {
            return deferralClass.getDeferralDecimals();
        } else {
            return 0;
        }
    };

    const getRetirementAgeDelta = () => {
        if (ObjectUtil.isUndefinedOrNull(priorRetirementAge)) {
            return 0;
        }
        return Math.round(retireAge - priorRetirementAge);
    };

    const hasRetirementAgeChanges = () => {
        return getRetirementAgeDelta() !== 0;
    };

    const renderChangedDeferrals = (deferrals, isGrandfathered) => {
        return deferrals.map((deferral, id) => {
            const showDecimals = deferral.value % 1 !== 0;

            return isGrandfathered ? (
                <span key={deferral.deferralTypeCode}>
                    {id !== 0 && id === deferrals.length - 1 ? (
                        <span> {reviewChangesTranslations.andYour}</span>
                    ) : null}
                    <span> {deferral.config.displayName}</span>
                    {id !== deferrals.length - 1 ? <span>, </span> : <span> </span>}
                </span>
            ) : (
                <span
                    key={deferral.config.displayName}
                    className="confirm-msg-part confirm-contribution-msg-part"
                >
                    {confirmChangesText[requestCR(id === 0, deferral)]}
                    <span>{deferral.config.displayName}</span>
                    {` ${contributionRate}`}
                    {to}
                    <span className="text-success">
                        <strong className="changed-deferral-confirm">
                            <NumericFormat
                                fixedDecimalScale={showDecimals}
                                decimalScale={getDecimals(deferral.value)}
                                value={deferral.value}
                                allowNegative={false}
                                prefix={deferralClass.getDeferralPrefix(deferral)}
                                suffix={deferralClass.getDeferralSuffix(deferral)}
                                displayType={"text"}
                                data-testid={`updated-contribution-${deferral.deferralTypeCode}`}
                            />
                        </strong>
                    </span>{" "}
                    ({effective}{" "}
                    <span data-testid={`deferral-effective-date-${deferral.deferralTypeCode}`}>
                        {deferralClass.getFormattedEffectiveDate(deferral)}
                    </span>
                    )
                    {id === deferrals.length - 1 && !hasRetirementAgeChanges() ? (
                        <span>. </span>
                    ) : (
                        " "
                    )}
                </span>
            );
        });
    };

    const getPayload = () => {
        return {
            deferrals: deferralClass.getChangedDeferrals("full"),
            retireAge: retireAge,
            hasInvestmentChange: showContinueButton()
        };
    };

    const getWasEnrolledInMaximizer = () => {
        return getMaximizer(primaryPlan).wasEnrolled;
    };

    const renderConfirmationData = (confirmations) => {
        return confirmations
            ? confirmations.map((confirmation) => {
                  return (
                      <div key={confirmation.planName}>
                          {hasContributionRateChanges() ? (
                              <div className="change-summary-row">
                                  <div className="change-summary-label">
                                      {confirmChangesText.confirmNum}:
                                  </div>
                                  <div
                                      className="change-summary-item"
                                      data-testid="confirmation-number"
                                  >
                                      {confirmation.confirmationNumber}
                                  </div>
                              </div>
                          ) : null}
                          {getWasEnrolledInMaximizer() ? (
                              <div className="change-summary-row">
                                  <div className="change-summary-label">
                                      {maximizer.leavingMaximizer.reviewConfirmationModal.label}:
                                  </div>
                                  <div className="change-summary-item">
                                      {maximizer.leavingMaximizer.reviewConfirmationModal.value}
                                  </div>
                              </div>
                          ) : null}
                          <div className="change-summary-row">
                              <div className="change-summary-label">
                                  {confirmChangesText.affectedPlan}:
                              </div>
                              <div className="change-summary-item" data-testid="plan-name">
                                  {confirmation.planName}
                              </div>
                          </div>
                      </div>
                  );
              })
            : null;
    };

    const getEquityMixDelta = () => {
        if (!canChangeEquityMix(primaryPlan, individualOptions)) {
            return 0;
        }
        return Math.round(equityMix) - priorEquityMix;
    };

    const hasEquityMixChanges = (ignoreWarnings) => {
        if (!ignoreWarnings && hasEquityMixErrors()) {
            return false;
        }
        return getEquityMixDelta() !== 0;
    };

    const hasEquityMixWarnings = () => {
        return ObjectUtil.isUndefinedOrNull(equityMixWarnings) ||
            (equityMixWarnings && equityMixWarnings.length === 0)
            ? false
            : equityMixWarnings.length > 0;
    };

    // InvestmentMix renamed to EquityMix
    const goToEquityMix = () => {
        dispatch(dispatchEventBus(ConfirmEvents.DONE_CLICK, this, getPayload()));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: ConfirmEvents.DONE_CLICK,
            payload: {
                ...getPayload()
            }
        });

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: "continue_to_equity_mix_button",
            payload: {
                ...getPayload()
            }
        });

        dispatch(setShowConfirmModal(false));
        if (!fullDeferralsChanged && modelDeferralsChanged) {
            dispatch(resetContributionSlidersOnly());
        }

        if (
            !ObjectUtil.isUndefinedOrNull(
                launchEquityMix(indivId, planId, equityMix, individualOptions)
            )
        ) {
            launchEquityMix(indivId, planId, equityMix, individualOptions);
        }
    };

    const showContinueButton = () => {
        return (
            hasEquityMixChanges() &&
            !hasEquityMixWarnings() &&
            !showWarningsOnlyModal &&
            !showHighContributionModal
        );
    };

    const handleConfirmationDone = (e) => {
        e.preventDefault();
        dispatch(dispatchEventBus(ConfirmEvents.DONE_CLICK, this, getPayload()));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: ConfirmEvents.DONE_CLICK,
            payload: {
                ...getPayload()
            }
        });
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: "confirmation_done_button",
            payload: {
                ...getPayload()
            }
        });

        setShowConfirmationModal(false);
        setShowWarningsOnlyModal(false);
        dispatch(setShowConfirmModal(false));
        dispatch(setShowReviewButton(false));

        if (
            (showWarningsOnlyModal && !retirementAgeChanged) ||
            (!fullDeferralsChanged && modelDeferralsChanged)
        ) {
            dispatch(resetSliderValues());
        } else {
            resetValues(
                indivId,
                primaryPlan,
                deferralClass.resetDeferralPriorValues(),
                deferralWarnings
            );
        }
    };

    const myContributionsHref = deferralClass.getMyContributionUrl(indivId, primaryPlan);

    const navigateToMyContribution = (event) => {
        event.preventDefault();
        window.location.href = deferralClass.getMyContributionUrl(indivId, primaryPlan);
    };

    const renderDeferralWarnings = (deferralWarnings) => {
        return deferralWarningsHasLength()
            ? deferralWarnings.map((warning) => {
                  if (warning === "mixPctAmtCode") {
                      return (
                          <div key={warning} className="alert alert-warning">
                              <p>
                                  {reviewChangesTranslations.warnings.grandfatheredDeferrals1}{" "}
                                  <span className="review-msg-part review-contribution-msg-part">
                                      {renderChangedDeferrals(
                                          deferralClass.getChangedDeferrals(
                                              DeferralConstants.category.FULL
                                          ),
                                          true
                                      )}
                                  </span>
                                  {reviewChangesTranslations.warnings.grandfatheredDeferrals2}
                                  <a
                                      href={myContributionsHref}
                                      onClick={(event) => navigateToMyContribution(event)}
                                  >
                                      {" "}
                                      {reviewChangesTranslations.warnings.myContributions}{" "}
                                  </a>
                                  {reviewChangesTranslations.warnings.grandfatheredDeferrals3}
                              </p>
                              <p>
                                  <span>
                                      {reviewChangesTranslations.warnings.grandfatheredDeferrals4}
                                  </span>
                              </p>
                          </div>
                      );
                  }
                  return (
                      <div key={warning} className="alert alert-warning">
                          <p>{warning}</p>
                      </div>
                  );
              })
            : null;
    };

    const renderShowRestrictions = () => {
        const showMessage = shouldShowDeferralRestrictionsMessage();

        switch (showMessage) {
            case true:
                return (
                    <p>
                        {app.deferrals.restrictions.messagePart1}
                        <span>{renderMaxLinks()}</span>
                        <span>
                            <br />
                            <br />
                            {app.deferrals.restrictions.continueRequest}
                        </span>
                    </p>
                );
            default:
                return (
                    <p>
                        {reviewChangesTranslations.warnings["model-only-1"]}
                        <span>
                            {renderCsorDeferrals(
                                deferralClass.getChangedDeferrals(DeferralConstants.category.MODEL)
                            )}
                        </span>
                        {reviewChangesTranslations.warnings["model-only-2"]}
                    </p>
                );
        }
    };

    const renderDeferralSubmissionWarnings = (submissionWarnings) => {
        return submissionWarnings?.length > 0
            ? submissionWarnings.map((warning) => {
                  return (
                      <p key={warning.message}>
                          {warning.message}.
                          <span className="review-msg-part review-contribution-msg-part">
                              {renderChangedDeferrals(
                                  deferralClass.getChangedDeferrals(DeferralConstants.category.FULL)
                              )}
                          </span>
                      </p>
                  );
              })
            : null;
    };

    const hasEquityMixErrors = function () {
        return equityMixWarnings && equityMixWarnings.length > 0;
    };

    const showEquityMixWarnings = () => {
        return hasEquityMixChanges(true) || hasEquityMixErrors();
    };

    const renderInvestmentWarnings = (investmentWarnings) => {
        return investmentWarnings.length > 0
            ? investmentWarnings.map((warning) => {
                  return (
                      <div key={warning.url} className="alert alert-warning investment-mix-warning">
                          <p>
                              {warning.message1}
                              {<span>{`"${warning.urlMessage}"`}</span>}
                              {warning.message2}
                          </p>
                      </div>
                  );
              })
            : null;
    };

    const shouldShowDeferralRestrictionsMessage = () => {
        return !deferralClass.canChangeDeferrals() && deferralClass.getHasDeferralRestrictions();
    };

    const renderMaxLinks = () => {
        return (
            <span>
                {app.deferrals.contributionType.heading.headline}{" "}
                {app.deferrals.restrictions.messagePart2}.
            </span>
        );
    };

    const renderCsorDeferrals = (deferrals) => {
        return deferrals.map((deferral, id) => {
            return (
                <span key={`${deferral.deferralTypeCode}`}>
                    {deferralClass.isCsorDeferral(deferral) ? (
                        <span>{retirementIncomeTranslations.estimated} </span>
                    ) : null}
                    {deferral.config.displayName}
                    {id !== deferrals.length - 1 ? <span>, </span> : null}
                </span>
            );
        });
    };

    const handleWhatIsThis = (event) => {
        event.preventDefault();
        if (event.target.className === "btn-link") {
            setShowInfo(!showInfo);
        } else {
            setShowInfo(false);
        }
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_LINK,
            selection: ConfirmEvents.INFO_CLICK
        });
    };

    const handleEnterPressForInfo = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleWhatIsThis(event);
        } else if (event.key === "Tab") {
            setShowInfo(false);
        }
    };

    const handleIPRCclick = () => {
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: ConfirmEvents.IPRC_CLICK
        });

        return (window.location.href = StringUtil.supplant(
            ReferenceData.URL_IN_PLAN_ROTH_CONVERSION,
            {
                individualId: indivId,
                groupId: planId
            }
        ));
    };

    const isThereAftertaxChanges = () => {
        return deferralSetupConfig.changedDeferralsFull.some((changedDeferral) =>
            AFTERTAX_CODES.includes(changedDeferral.deferralTypeCode)
        );
    };

    const showIPRC = () => {
        return inPlanRothConversionInd && isThereAftertaxChanges();
    };

    return (
        <>
            {confirmationData || priorRetirementAge || showWarningsOnlyModal ? (
                <Modal
                    dialogClassName="confirm-changes-container"
                    show={showConfirmModal || showWarningsOnlyModal}
                    onClick={(event) => handleWhatIsThis(event)}
                >
                    <div id={confirmModalId} data-testid="confirm-changes">
                        <div className="change-summary-dialog">
                            <div className="change-summary-container">
                                <div className="change-summary-middle-container">
                                    <Modal.Header className="modal-header">
                                        <Modal.Title
                                            className="modal-title confirm-title"
                                            id={`${showWarningsOnlyModal ? reviewChangesTranslations.warnings.title : confirmModalTitle}Label`}
                                            data-testid="confirm-modal-title"
                                        >
                                            {showWarningsOnlyModal
                                                ? reviewChangesTranslations.warnings.title
                                                : confirmModalTitle}
                                        </Modal.Title>
                                        {!showWarningsOnlyModal && (
                                            <div className="actions">
                                                <Print
                                                    text={printLbl}
                                                    printElementClass={
                                                        "change-summary-middle-container"
                                                    }
                                                    additionalClasses={"printAdditionalClass"}
                                                    sideEffects={null}
                                                    replace={titleReplace}
                                                />
                                            </div>
                                        )}
                                        <div className="submission-warnings">
                                            {isHighContribution && showWarningsOnlyModal && (
                                                <div
                                                    className="alert alert-warning"
                                                    data-testid="high-contributions-warning"
                                                >
                                                    <p>
                                                        {
                                                            reviewChangesTranslations.warnings
                                                                .highContributions
                                                        }
                                                    </p>
                                                </div>
                                            )}
                                            {hasContributionRateChanges(true)
                                                ? renderDeferralWarnings(deferralWarnings)
                                                : null}
                                            {deferralClass.getChangedDeferrals(
                                                DeferralConstants.category.MODEL
                                            )?.length > 0 ? (
                                                <div className="alert alert-warning">
                                                    {renderShowRestrictions()}
                                                </div>
                                            ) : null}
                                            {deferralClass.getChangedDeferrals(
                                                DeferralConstants.category.GRANDFATHERED
                                            )?.length > 0 ? (
                                                <div className="alert alert-warning">
                                                    <p>
                                                        {
                                                            reviewChangesTranslations.warnings
                                                                .grandfatheredDeferrals1
                                                        }{" "}
                                                        <span className="review-msg-part review-contribution-msg-part">
                                                            {renderChangedDeferrals(
                                                                deferralClass.getChangedDeferrals(
                                                                    DeferralConstants.category
                                                                        .GRANDFATHERED
                                                                ),
                                                                true
                                                            )}
                                                        </span>
                                                        {
                                                            reviewChangesTranslations.warnings
                                                                .grandfatheredDeferrals2
                                                        }
                                                        {showWarningsOnlyModal ? (
                                                            <a
                                                                href={myContributionsHref}
                                                                onClick={(event) =>
                                                                    navigateToMyContribution(event)
                                                                }
                                                            >
                                                                {" "}
                                                                {
                                                                    reviewChangesTranslations
                                                                        .warnings.myContributions
                                                                }{" "}
                                                            </a>
                                                        ) : (
                                                            <span>
                                                                {" "}
                                                                {
                                                                    reviewChangesTranslations
                                                                        .warnings.myContributions
                                                                }{" "}
                                                            </span>
                                                        )}
                                                        {
                                                            reviewChangesTranslations.warnings
                                                                .grandfatheredDeferrals3
                                                        }
                                                    </p>
                                                    {!showWarningsOnlyModal ? (
                                                        <p>
                                                            <span>
                                                                {
                                                                    reviewChangesTranslations
                                                                        .warnings
                                                                        .grandfatheredDeferrals4
                                                                }
                                                            </span>
                                                        </p>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                            {hasContributionRateChanges(true) ? (
                                                <div
                                                    className={
                                                        deferralSubmissionWarnings?.length
                                                            ? "alert alert-warning"
                                                            : ""
                                                    }
                                                >
                                                    {renderDeferralSubmissionWarnings(
                                                        deferralSubmissionWarnings
                                                    )}
                                                </div>
                                            ) : null}
                                            {showEquityMixWarnings()
                                                ? renderInvestmentWarnings(equityMixWarnings)
                                                : null}
                                        </div>
                                        {!showWarningsOnlyModal && (
                                            <div className="with-success-check">
                                                <i className="em-checkbox-icon" />
                                                {!deferralClass.getHasDeferralWarnings(
                                                    deferralWarnings,
                                                    deferralSubmissionWarnings
                                                ) ? (
                                                    <span>
                                                        {renderChangedDeferrals(
                                                            deferralClass.getChangedDeferrals(
                                                                "full"
                                                            )
                                                        )}
                                                    </span>
                                                ) : null}
                                                {hasRetirementAgeChanges() ? (
                                                    <span className="confirm-msg-part confirm-retirement-msg-part">
                                                        {confirmChangesText[requestRA()]}
                                                        <span className="text-success">
                                                            <strong>{retireAge}</strong>
                                                        </span>
                                                        .
                                                    </span>
                                                ) : null}
                                                {hasEquityMixChanged(
                                                    false,
                                                    individualOptions,
                                                    priorEquityMix,
                                                    equityMix
                                                ) ? (
                                                    <span>
                                                        <span className="review-msg-part review-investment-msg-part">
                                                            {reviewChangesTranslations.requestIM}
                                                        </span>
                                                        <span className="review-msg-part review-investment-msg-part">
                                                            <span className="text-success">
                                                                <strong> {priorEquityMix}%</strong>
                                                            </span>{" "}
                                                            {reviewChangesTranslations.to}
                                                            <span className="text-success">
                                                                <strong> {equityMix}%</strong>
                                                            </span>
                                                            .
                                                        </span>
                                                    </span>
                                                ) : null}
                                            </div>
                                        )}
                                        {
                                            <div className="modal-description">
                                                {showWarningsOnlyModal &&
                                                hasRetirementAgeChanges() ? (
                                                    <span className="confirm-msg-part confirm-retirement-msg-part">
                                                        {confirmChangesText[requestRA()]}
                                                        <span className="text-success">
                                                            <strong>{retireAge}</strong>
                                                        </span>
                                                        .
                                                    </span>
                                                ) : null}
                                            </div>
                                        }
                                    </Modal.Header>
                                    {!showWarningsOnlyModal && (
                                        <Modal.Body className="change-summary-body">
                                            <div className="change-summary-content">
                                                {renderConfirmationData(confirmationData)}
                                            </div>
                                            <div className="disclaimer">
                                                {hasContributionRateChanges() ? (
                                                    <small
                                                        className="confirm-note"
                                                        data-testid="confirm-note"
                                                    >
                                                        {confirmChangesText.confirmNote}
                                                    </small>
                                                ) : null}
                                                {hasEquityMixChanges() &&
                                                !equityMixChangeOnly(
                                                    false,
                                                    deferralList,
                                                    priorRetirementAge,
                                                    retireAge,
                                                    individualOptions,
                                                    priorEquityMix,
                                                    equityMix,
                                                    deferralWarnings,
                                                    deferralSubmissionWarnings
                                                ) ? (
                                                    <small className="text-muted">
                                                        <br />
                                                        {reviewChangesTranslations.mixChangesNote}
                                                    </small>
                                                ) : null}
                                            </div>
                                        </Modal.Body>
                                    )}
                                </div>
                                <Modal.Footer className="modal-footer">
                                    <div
                                        className="footer-content"
                                        style={showIPRC() ? {} : { width: "22%" }}
                                    >
                                        {showIPRC() && (
                                            <div
                                                className={`iprc-btn-container ${showIPRC() ? "" : "hidden"}`}
                                            >
                                                <button
                                                    type="button"
                                                    className="iprc-btn"
                                                    onClick={handleIPRCclick}
                                                >
                                                    {
                                                        app.deferrals.contributionConfirmation
                                                            .rothConversion
                                                    }
                                                </button>
                                                <OverlayTrigger
                                                    show={showInfo}
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`whatIsThisTooltip`}>
                                                            {
                                                                app.deferrals
                                                                    .contributionConfirmation
                                                                    .rothTooltipInfo
                                                            }
                                                        </Tooltip>
                                                    }
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="em-info-icon"
                                                    />
                                                </OverlayTrigger>
                                                <button
                                                    onClick={(event) => handleWhatIsThis(event)}
                                                    onKeyDown={(event) =>
                                                        handleEnterPressForInfo(event)
                                                    }
                                                    className="btn-link"
                                                >
                                                    {
                                                        app.deferrals.contributionConfirmation
                                                            .rothTooltipTitle
                                                    }
                                                </button>
                                            </div>
                                        )}
                                        {!showContinueButton() ? (
                                            <div className="done-btn-container">
                                                <button
                                                    type="button"
                                                    className="done-btn"
                                                    onClick={handleConfirmationDone}
                                                >
                                                    {!showWarningsOnlyModal || retirementAgeChanged
                                                        ? app.common.labels.done
                                                        : app.common.labels.okay}
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="continue-btn-container">
                                                <button
                                                    type="button"
                                                    className="continue-btn"
                                                    onClick={goToEquityMix}
                                                >
                                                    {app.common.labels.continue}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </Modal.Footer>
                            </div>
                        </div>
                    </div>
                </Modal>
            ) : (
                <Loader />
            )}
        </>
    );
};
ConfirmChanges.propTypes = {
    showConfirmModal: PropTypes.bool,
    showWarningsOnlyModal: PropTypes.bool,
    setShowConfirmationModal: PropTypes.func,
    setShowWarningsOnlyModal: PropTypes.func,
    showHighContributionModal: PropTypes.bool,
    deferralClass: PropTypes.object,
    isHighContribution: PropTypes.bool,
    retirementAgeChanged: PropTypes.bool
};
export default ConfirmChanges;
