import axios from "axios";
import DateUtil from "core-ui/client/src/app/DateUtil";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { isUndefinedOrNull } from "gw-shared-components";
import * as Hub from "hub";
import { isNil as _isNil, cloneDeep as _cloneDeep } from "lodash";

import {
    addExternalAssets,
    createMigratedRetirementAssetAction,
    updateMigratedRetirementAssetAction,
    deleteMigratedRetirementAssetAction,
    handleDeleteExternalAsset,
    saveLinkedAccountPreferencesAction,
    saveTermsOfServiceAction,
    callForAATermsOfServiceAction,
    splitAssetsUpdateProjectionsCloseLoader
} from "../actions/otherAssets/otherAssetsActions";
import {
    showLoader as setShowLoader,
    setCurrentFault
} from "../actions/shared/sharedActionCreators";
import Errors from "../constants/CodeErrors";
import OtherAssetsConstants from "../constants/OtherAssetsConstants";
import HubConfigFactory from "../factories/HubConfigFactory";
import InitDataFactory from "../factories/InitDataFactory";
import RequestFactory from "../factories/RequestFactory";
import {
    participantHasSpouse,
    isParticipantInvestorId,
    isParticipantPersonId
} from "../selectors/otherAssetsSelectors";
import { formatTimestamp } from "../utils/dateUtils";
import { handleFault } from "../utils/errorUtils";
import { getVhFactorBoundsFromVhFactors } from "../utils/hubUtils";
import { getUrl, isLocalhost, hasMocks } from "../utils/urlUtils";

const logger = ExternalLogger.getInstance("OtherAssetsService");

export const ACTION_CREATE = "CREATE";
export const ACTION_UPDATE = "UPDATE";
export const ACTION_DELETE = "DELETE";

const getMethodForActionType = (action) => {
    switch (action) {
        case ACTION_CREATE:
            return RequestFactory.HTTP_METHOD.POST;

        case ACTION_UPDATE:
            return RequestFactory.HTTP_METHOD.PUT;

        case ACTION_DELETE:
            return RequestFactory.HTTP_METHOD.DELETE;

        default:
            throw new Error("action incorrectly passed to saveMigrated");
    }
};

const handleResult = function (result) {
    if (isUndefinedOrNull(result) || isUndefinedOrNull(result.data)) {
        logger.error("saveOtherAssetsResult( Bad response data. )");
        throw "There is no response data.";
    } else {
        return result.data;
    }
};

const handleError = function (error, dispatch) {
    logger.error("saveOtherAssetsFault( error = {0} )", [error.message]);
    if (!isUndefinedOrNull(dispatch)) {
        logger.warn("{handleError} - dispatch is not defined");
        dispatch(setCurrentFault(error));
    }
    return Promise.reject(error);
};

/**
 * Workaround for delete external assets; service is sending null in result.data
 * when delete has completed and preventing further processing to update list of assets.
 * Bypass response.data is null check.
 * @param {*} result
 */
// const handleDeleteResult = function (result) {
//     if (isUndefinedOrNull(result)) {
//         logger.error("saveOtherAssetsResult( Bad response data. )");
//         throw "There is no response data.";
//     } else {
//         return result.data;
//     }
// };

/**
 * @description saves migrated retirement income assets (monthly income in retirement)
 * @param action
 * @param asset
 * @returns {Promise.<T>}
 */
export const saveMigratedRetirementAsset = (action, asset, investorId, dispatch) => {
    dispatch(setShowLoader(true));

    let err = false;
    if (!asset) {
        err = Errors.Assets.ASSET_MISSING;
    } else if (!asset.investorId) {
        err = Errors.Assets.INVESTOR_ID_MISSING;
    } else if (!investorId && action !== ACTION_CREATE) {
        err = Errors.Assets.INVESTOR_ACCOUNT_ID_MISSING;
    }
    if (err) {
        logger.error(err);
        return Promise.reject(err);
    }
    const params = asset;
    const urlParams = {
        investorId,
        urlEnding: action !== ACTION_CREATE ? "/" + asset.investorAccountId : ""
    };

    const url = StringUtil.supplant(getUrl("saveMigratedRetirementAsset"), urlParams);
    const method = getMethodForActionType(action);
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;

    const request = RequestFactory.create(url, params, method, contentType);

    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        (err) => {
            return Promise.reject(err, dispatch);
        }
    );

    delete request.headers.samlToken;

    return axios(request).then(handleResult, handleError);
};

/**
 * @description create a migrated retirement income asset. ma-ui expects promises.
 * @param {object} asset
 * @param {number} personId
 * @param {object} state
 * @param {function} dispatch
 * @returns {Promise.<T>}
 */
export const createMigratedRetirementAsset = function (asset, personId, state, dispatch) {
    if (isUndefinedOrNull(asset)) {
        logger.error("asset must be defined when calling createMigratedRetirementAsset");
    }

    if (isUndefinedOrNull(personId)) {
        logger.error("personId must be defined when calling createMigratedRetirementAsset");
    }

    const createAssetSuccess = function ({ data }) {
        const clonedAsset = _cloneDeep(asset);
        clonedAsset.investorId = data.investorId;
        clonedAsset.investorAccountId = data.investorAccountId;
        clonedAsset.lastModifiedOn = data.lastModifiedOn;
        const assetObj = InitDataFactory.createRetirementAsset(clonedAsset);
        // Should we add monthlyIncomeEST to the createRetirementAsset ?
        assetObj.monthlyIncomeEST = getMigratedAssetIncome(assetObj, state);

        dispatch(createMigratedRetirementAssetAction(assetObj));
        dispatch(splitAssetsUpdateProjectionsCloseLoader());
        return assetObj;
    };

    return saveMigratedRetirementAsset(ACTION_CREATE, asset, personId, dispatch).then(
        createAssetSuccess
    );
};

/**
 * @description update a migrated retirement income asset. ma-ui expects promises.
 * @param {object} assetToUpdate
 * @param {number} personId
 * @param {object} state
 * @param {function} dispatch
 * @returns {Promise.<T>}
 */
export const updateMigratedRetirementAsset = function (assetToUpdate, personId, state, dispatch) {
    if (isUndefinedOrNull(assetToUpdate)) {
        logger.error("asset must be defined when calling updateMigratedRetirementAsset");
    }

    const updateAssetSuccess = function () {
        const asset = InitDataFactory.createRetirementAsset(assetToUpdate);
        asset.lastModifiedOn = DateUtil.getDateFormatted("YYYY-MM-DD"); // make as a util ?
        asset.monthlyIncomeEST = getMigratedAssetIncome(asset, state);

        dispatch(updateMigratedRetirementAssetAction(asset));
        dispatch(splitAssetsUpdateProjectionsCloseLoader());

        return asset;
    };
    return saveMigratedRetirementAsset(ACTION_UPDATE, assetToUpdate, personId, dispatch).then(
        updateAssetSuccess
    );
};

/**
 * @description delete a migrated retirement income asset. ma-ui expects promises.
 * @param {object} asset
 * @param {number} investorId
 * @param {function} dispatch
 * @returns {Promise.<T>}
 */
export const deleteMigratedRetirementAsset = (asset, dispatch) => {
    if (isUndefinedOrNull(asset) || isUndefinedOrNull(asset.investorId)) {
        logger.error(
            "asset and investorId must be defined when calling deleteMigratedRetirementAsset"
        );
    }

    const deleteIIRSuccess = function () {
        dispatch(deleteMigratedRetirementAssetAction(asset));
        dispatch(splitAssetsUpdateProjectionsCloseLoader());
        return asset.investorAccountId;
    };

    const deleteIIRFault = function (fault) {
        logger.error("deleteMigratedRetirementAsset( fault = {0} )", [fault.message]);
        return fault;
    };

    return saveMigratedRetirementAsset(ACTION_DELETE, asset, asset.investorId, dispatch).then(
        deleteIIRSuccess,
        deleteIIRFault
    );
};

/**
 * @description create external asset for migrated user.  ma-ui expects promises.
 * @param {object} asset
 * @param {number} investorId
 * @param {object} state
 * @param {function} dispatch
 * @returns {Promise.<T>}
 */
export const createExternalAsset = (asset, investorId, state, dispatch) => {
    if (isUndefinedOrNull(asset)) {
        logger.error("asset must be defined when calling createExternalAsset");
    }

    if (isUndefinedOrNull(investorId)) {
        investorId = asset.investorId;
    }

    const createAssetSuccess = (response) => {
        let data = null;

        if (response && response.data) {
            data = response.data;
        } else if (response) {
            logger.error("response has no data Fault( fault = {0} )");
            data = response;
        }

        const payload = InitDataFactory.createExternalAsset(data);
        payload.monthlyIncomeEST = getMigratedAssetIncome(payload, state);
        dispatch(addExternalAssets(payload));
        dispatch(splitAssetsUpdateProjectionsCloseLoader());

        return payload;
    };

    return saveMigratedRetirementAsset(ACTION_CREATE, asset, investorId, dispatch).then(
        createAssetSuccess
    );
};

/**
 * @description delete a migrated external asset. ma-ui expects promises.
 * @param {object} asset
 * @param {number} investorId
 * @param {function} dispatch
 * @returns {Promise.<T>}
 */
export const deleteExternalAsset = function (asset, investorId, dispatch) {
    if (isUndefinedOrNull(asset) || isUndefinedOrNull(investorId)) {
        logger.error("asset must be defined when calling deleteExternalAsset");
    }

    dispatch(setShowLoader(true));

    const deleteExternalAssetSuccess = function (response) {
        const clonedAsset = _cloneDeep(asset);
        const data = { ...clonedAsset, investorId };
        dispatch(handleDeleteExternalAsset(data));
        dispatch(splitAssetsUpdateProjectionsCloseLoader());

        return response;
    };

    return saveMigratedRetirementAsset(ACTION_DELETE, asset, investorId, dispatch).then(
        deleteExternalAssetSuccess
    );
};

export const getNonMigratedAssetIncome = (asset, state) => {
    if (_isNil(asset)) {
        return 0;
    } else {
        const { participant, applicationSettings, investments, primaryPlan } = state;

        const rateOfInflation = primaryPlan.IRSLimits.rateOfInflation;
        const age = participant.age;
        const retirementAge = participant.retirementAge;
        const term = applicationSettings.projectionSettings.term;
        const confidence = applicationSettings.projectionSettings.confidence;
        const equityPercent = investments.equityMix;
        const vhFactorSet = participant.projectionsMap;
        const vhFactorSetBounds = getVhFactorBoundsFromVhFactors(vhFactorSet);

        const dateOfBirth = formatTimestamp(participant.dateOfBirth);
        const assetIncome =
            asset.assetType === OtherAssetsConstants.OAB_TRS
                ? Hub.getProjectedLinkedAccountValue(
                      asset,
                      rateOfInflation,
                      age,
                      retirementAge,
                      equityPercent,
                      confidence,
                      vhFactorSet,
                      vhFactorSetBounds,
                      term,
                      dateOfBirth
                  )
                : Hub.getProjectedOtherAssetValue(
                      asset,
                      rateOfInflation,
                      age,
                      retirementAge,
                      equityPercent,
                      confidence,
                      vhFactorSet,
                      vhFactorSetBounds,
                      term,
                      asset.employerContribution
                  );

        return assetIncome;
    }
};

/**
 * @description sends factors to the hub to get the caluclated asset income
 * @param {object} asset
 * @param {object} state
 * @param {number|undefined} term (optional)
 * @returns {number}
 */
export const getMigratedAssetIncome = (asset, state, term) => {
    if (_isNil(asset)) {
        return 0;
    }
    const { investments, primaryPlan, irsLimitsRefData, participant, applicationSettings, spouse } =
        state;
    // Determine if asset is for primary ppt or spouse
    // external and retirement income asset
    let user = participant;
    const irsLimits = primaryPlan.IRSLimits;

    if (participantHasSpouse(spouse)) {
        user = isParticipantInvestorId(participant, asset.investorId) ? participant : spouse;
    }

    user = isParticipantPersonId(participant, asset.investorId) ? participant : spouse;

    const nondiscretionaryContribution = irsLimits.nondiscretionaryContribution
        ? irsLimits.nondiscretionaryContribution
        : 0;

    const age = user.age;
    const retireAge = user.retirementAge;

    //   Note: Goal Modal only applies Monthly/Yeary term on primary user;
    //         spouse defaults to primary user setting; If logic in Goal Modal is fixed, change next line
    const { confidence, rateOfInflation } = applicationSettings.projectionSettings;
    const appTerm = term ? term : applicationSettings.projectionSettings.term;
    const equityPercent = investments.equityMix;

    // use equityMix of external asset for external assets
    const vhFactorSet = user.projectionsMap;
    const vhFactorSetBounds = getVhFactorBoundsFromVhFactors(vhFactorSet);

    const employer = asset.dataSource ? String(asset.dataSource).toUpperCase() === "EMP" : false;
    const dateOfBirth = formatTimestamp(user.dateOfBirth);

    if (asset.assetType === "retirementIncome") {
        const retirementIncomeArray = asset;

        return Hub.getProjectedRetirementIncomeAssetValue(
            retirementIncomeArray,
            age,
            retireAge,
            equityPercent,
            confidence,
            vhFactorSet,
            vhFactorSetBounds,
            rateOfInflation,
            appTerm
        );
    } else if (asset.assetType === "externalAsset") {
        let externalPlan = {};

        externalPlan = HubConfigFactory.createIntegratedExternalAsset(
            asset,
            user,
            retireAge,
            equityPercent,
            irsLimitsRefData,
            irsLimits
        );

        let equityPct = externalPlan.equityMix > 0 ? externalPlan.equityMix : equityPercent;
        equityPct = asset.equityMix;

        return Hub.getProjectedExternalAssetValue(
            externalPlan,
            age,
            retireAge,
            equityPct,
            confidence,
            vhFactorSet,
            vhFactorSetBounds,
            appTerm,
            nondiscretionaryContribution,
            employer
        );
    } else {
        // other account balance
        if (asset.assetType === OtherAssetsConstants.OAB_TRS) {
            return Hub.getProjectedLinkedAccountValue(
                asset,
                rateOfInflation,
                age,
                retireAge,
                equityPercent,
                confidence,
                vhFactorSet,
                vhFactorSetBounds,
                appTerm,
                dateOfBirth
            );
        } else {
            return Hub.getProjectedOtherAssetValue(
                asset,
                rateOfInflation,
                age,
                retireAge,
                equityPercent,
                confidence,
                vhFactorSet,
                vhFactorSetBounds,
                appTerm
            );
        }
    }
};

/**
 * @description searches for the fund ticker. ma-ui expects promises.
 * @param {object} searchParam
 * @returns {Promise.<T>}
 */
export const searchFundTicker = (searchParam) => {
    const searchResult = function (result) {
        return result.data;
    };
    const searchFault = function (fault) {
        logger.error("searchFundTicker Fault( fault = {0} )", [fault.message]);
        return fault;
    };

    const url = StringUtil.supplant(getUrl("getFundTicker"), { searchParam });
    const method = RequestFactory.HTTP_METHOD.GET;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, searchParam, method, contentType);

    delete request.headers.samlToken;

    return axios(request).then(handleResult, handleError).then(searchResult, searchFault);
};

const handleGetFundNamesResult = function (result) {
    if (isUndefinedOrNull(result) || isUndefinedOrNull(result.data)) {
        logger.error("saveOtherAssetsResult( Bad response data. )");
        throw "There is no response data.";
    } else {
        return result.data.data;
    }
};

/**
 * @description This method is called from a component within ma-ui and the data is returned to ma-ui
 * Because the call is external, we bypass redux, make the axios call here and directly
 * return the data
 * @param {number} investorAccountId
 */
export const getFundNames = (investorAccountId) => {
    const url = StringUtil.supplant(getUrl("getFundNames"), { investorAccountId });
    const method = RequestFactory.HTTP_METHOD.GET;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, null, method, contentType);

    delete request.headers.samlToken;

    return axios(request).then(handleGetFundNamesResult, handleError);
};

/**
 * @description get terms of service. ma-ui expects promises.
 * @param {number} investorId
 * @param {function} dispatch
 * @returns {Promise.<T>}
 */
export const getTermsOfService = function (investorId, dispatch) {
    const url = StringUtil.supplant(getUrl("getTOS"), { investorId });
    const method = RequestFactory.HTTP_METHOD.GET;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;

    const request = RequestFactory.create(url, null, method, contentType);

    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        (err) => {
            return Promise.reject(err, dispatch);
        }
    );

    delete request.headers.samlToken;

    return axios(request)
        .then(handleResult, handleError)
        .then(({ data }) => {
            dispatch(callForAATermsOfServiceAction(true));
            if (data) {
                dispatch(saveTermsOfServiceAction(data));
            }
            return {
                saved: data.isTOSAccepted,
                uiToken: data.uiToken
            };
        });
};

/**
 * @description saves terms of service. ma-ui expects promises.
 * @param {number} investorId
 * @param {function} dispatch
 * @returns {Promise.<T>}
 */
export const saveTermsOfService = function (investorId, dispatch) {
    const url = getUrl("saveTOS");
    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const params = {
        investorId,
        isTOSAccepted: true
    };

    const request = RequestFactory.create(url, params, method, contentType);

    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        (err) => {
            return Promise.reject(err, dispatch);
        }
    );

    delete request.headers.samlToken;

    return axios(request)
        .then(handleResult, handleError)
        .then(({ data }) => {
            if (data) {
                dispatch(saveTermsOfServiceAction(data));
            }
            return data.uiToken;
        });
};

/**
 * @description save linked account preferences. ma-ui expects promises.
 * @param {array} accountList
 * @param {number} investorId
 * @param {function} dispatch
 * @returns {Promise.<T>}
 */
export const saveLinkedAccountPreferences = function (accountList, investorId, dispatch) {
    const url = StringUtil.supplant(getUrl("manageLinkedAccounts"), { investorId });
    const method = RequestFactory.HTTP_METHOD.PUT;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const params = accountList;

    const request = RequestFactory.create(url, params, method, contentType);

    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        (err) => {
            return Promise.reject(err, dispatch);
        }
    );

    delete request.headers.samlToken;

    return axios(request)
        .then(handleResult, handleError)
        .then((response) => {
            if (response.data) {
                dispatch(saveLinkedAccountPreferencesAction(response.data));
                dispatch(setShowLoader(false));
                return response.data;
            } else {
                logger.error("saveLinkedAccountPreferences( Bad response data. )");
            }
        });
};

export const switchPlanContextAction = function (asset) {
    const planId = asset.planId;

    window.dispatchEvent(new CustomEvent("switch-plan", { detail: { planId } }));
};

export const getAccountsUpdate = (payload) => {
    const service = "getAccountsLite";
    const url = getUrl(service);
    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED;

    const mock = window.location.origin + window.location.pathname + "data/get-accounts-lite.json";
    const updatedUrl = isLocalhost && hasMocks() ? mock : url;

    const formData = new FormData();
    formData.append("csrf", payload.csrf);

    return axios({
        method: method,
        url: updatedUrl,
        data: formData,
        headers: { "content-type": contentType },
        xhrFields: { withCredentials: true }
    })
        .then((response) => {
            if (response && response.data) {
                return response.data.spData;
            }
        })
        .catch((err) => {
            logger.error(method + "getAccountsLite Service Error: {0}", [err.message]);
            return handleFault(err, "getAccountsLiteFault");
        });
};

export const getMyLifeGoalsData = () => {
    const service = "getMyLifeGoals";
    const url = getUrl(service);
    const method = RequestFactory.HTTP_METHOD.POST;

    const formData = new FormData();
    formData.append("csrf", window.csrf);

    return axios({
        method: method,
        url: url,
        data: formData,
        withCredentials: true
    })
        .then((response) => {
            if (response && response.data) {
                return response.data.spData;
            }
        })
        .catch((err) => {
            logger.error(method + "getMyLifeGoals Service Error: {0}", [err.message]);
            return handleFault(err, "getMyLifeGoalsFault");
        });
};

export const getHistories = (csrf, body) => {
    const formData = new FormData();
    formData.append("csrf", csrf);
    for (const key in body) {
        formData.append(key, body[key]);
    }

    return axios({
        method: RequestFactory.HTTP_METHOD.POST,
        url: getUrl("getHistories"),
        data: formData,
        withCredentials: true
    });
};

export const getAccounts2 = (csrf, lastServerChangeId) => {
    const url = getUrl("getAccounts2");
    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED;

    const formData = new FormData();
    formData.append("csrf", csrf);
    formData.append("apiClient", "WEB");
    if (lastServerChangeId) {
        formData.append("lastServerChangeId", lastServerChangeId);
    }

    return axios({
        method: method,
        url: url,
        data: formData,
        withCredentials: true,
        headers: { "content-type": contentType },
        xhrFields: { withCredentials: true }
    });
};
