import React from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { generatePath } from "react-router-dom";

import ReferenceData from "../../../../constants/ReferenceData";
import EventBusEvents from "../../../../events/eventBusEvents";
import { calculateRoRType } from "../../../quickView/helpers";
import { NO_YEAR_DIFFERENCE } from "../../../quickView/helpers/calculateRoRType/calculateRoRType";
import type IQuickViewAccount from "../../../quickView/types";
import type { QuickViewTranslations } from "../../types";

import { RateOfReturnDecreaseIcon, RateOfReturnIncreaseIcon } from "./icons";

type RateOfReturnProps = {
    planDetails: IQuickViewAccount;
    translations: QuickViewTranslations;
};

const RateOfReturn = ({ planDetails, translations }: RateOfReturnProps) => {
    // Early return null if there are no rate of return
    if (!planDetails?.ror?.periodRateOfReturn) {
        return null;
    }

    const { rate, ratePeriod } = calculateRoRType(planDetails);
    const { ror: rateOfReturnTranslations, rateOfReturn: rateOfReturnLabel } = translations;

    const period =
        ratePeriod === NO_YEAR_DIFFERENCE
            ? rateOfReturnTranslations.ytd
            : `${ratePeriod} ${rateOfReturnTranslations.year}`;

    const href = generatePath(ReferenceData.URL_HYPERLINK_RATE_OF_RETURN, {
        individualId: planDetails.indId,
        groupId: planDetails.gaId
    });

    const isIncreaseRate = parseFloat(rate as string) >= 0;
    const ror = `${rate}% (${period})`;

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_LINK,
            selection: EventBusEvents.QUICK_VIEW.RATE_OF_RETURN_CLICKED
        });
        window.location.href = href;
    };

    const ariaLabel = `${rateOfReturnLabel} ${isIncreaseRate ? rateOfReturnTranslations.increased : rateOfReturnTranslations.decreased} ${ror}`;

    return (
        <div className="rateOfReturn-wrapper">
            <a
                href={href}
                className={`quickview-rateOfReturn ${isIncreaseRate ? "rateOfReturn-increase" : "rateOfReturn-decrease"}`}
                onClick={handleClick}
                aria-label={ariaLabel}
            >
                {isIncreaseRate ? <RateOfReturnIncreaseIcon /> : <RateOfReturnDecreaseIcon />}
                <span className="rateOfReturn-value">{ror}</span>
            </a>
        </div>
    );
};

export default RateOfReturn;
