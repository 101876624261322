import {
    ROUTE_MANAGED_ACCOUNTS_NEW_MARKETING,
    ROUTE_MANAGED_ACCOUNTS_INTRO,
    ROUTE_MANAGED_ACCOUNTS_STARTED,
    ROUTE_MANAGED_ACCOUNTS_NEW,
    ROUTE_ONLINE_ADVICE_NEW,
    ROUTE_OPEN_ACCOUNT,
    ROUTE_OPEN_ACCOUNT_PS,
    ROUTE_OPEN_ACCOUNT_IRA,
    ROUTE_PERSONAL_STRATEGY_PLUS_ABOUT,
    ROUTE_OPEN_ACCOUNT_OPTIONS,
    ROUTE_ROLLOVER,
    ROUTE_OPEN_BANK_ACCOUNT_V2,
    ROUTE_IRA_ACCOUNT_FUNDING
} from "../../../../routes";

const SUB_PAGE_NO_SIDEBAR_ROUTES = [
    ROUTE_OPEN_ACCOUNT,
    ROUTE_OPEN_ACCOUNT_PS,
    ROUTE_OPEN_ACCOUNT_IRA,
    ROUTE_MANAGED_ACCOUNTS_NEW_MARKETING,
    ROUTE_MANAGED_ACCOUNTS_INTRO,
    ROUTE_MANAGED_ACCOUNTS_STARTED,
    ROUTE_MANAGED_ACCOUNTS_NEW,
    ROUTE_ONLINE_ADVICE_NEW,
    ROUTE_PERSONAL_STRATEGY_PLUS_ABOUT,
    ROUTE_OPEN_ACCOUNT_OPTIONS,
    ROUTE_ROLLOVER,
    ROUTE_OPEN_BANK_ACCOUNT_V2,
    ROUTE_IRA_ACCOUNT_FUNDING
];

export default SUB_PAGE_NO_SIDEBAR_ROUTES;
