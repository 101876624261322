import React from "react";

type Props = {
    size?: number;
};

const RateOfReturnDecreaseIcon = ({ size = 10 }: Props) => {
    return (
        <svg
            aria-hidden="true"
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.98611 7.5C9.98611 7.88086 9.62531 8.18961 9.18023 8.18961L1.92732 8.18961C1.48225 8.18961 1.12144 7.88086 1.12144 7.5C1.12144 7.11914 1.48225 6.81039 1.92732 6.81039H8.37435V1.29353C8.37435 0.912669 8.73516 0.603921 9.18023 0.603921C9.62531 0.603921 9.98611 0.912669 9.98611 1.29353L9.98611 7.5ZM1.56984 0.0123736L9.75008 7.01237L8.61039 7.98763L0.430157 0.987626L1.56984 0.0123736Z"
                fill="#D6281F"
            />
        </svg>
    );
};
export default RateOfReturnDecreaseIcon;
