import { ObjectUtil } from "gw-shared-components";
import { cloneDeep as _cloneDeep, findIndex as _findIndex } from "lodash";
import { batch } from "react-redux";

import {
    setBaselineDeferrals,
    setBaselineRetirementAge,
    setBaselineEquityMix
} from "../actions/baseline/baselineActions";
import {
    setShowConfirmModal,
    setConfirmationData,
    setLastChanges
} from "../actions/confirmChanges/confirmChangesAction";
import {
    resetChangedDeferrals,
    setupSummarySlider,
    deferralChangedPrimaryPlanMaximzerUnEnrolling,
    clearAutoIncreaseOrFutureDated,
    initializeDeferralSetupConfigParams,
    setOriginalDeferralSummaryTotal
} from "../actions/deferrals/deferralActions";
import { RESET_HAS_DEFERRAL_CHANGED } from "../actions/deferrals/deferralActionTypes";
import { setEquityMixWarnings, setEquityMix } from "../actions/investments/investmentsActions";
import {
    setParticipantDeferrals,
    updateParticipantSalary,
    setRetirementAge
} from "../actions/participantDetails/participantDetailsActions";
import {
    setMaximizerIsOngoing,
    setDeferralRestrictions,
    setAllowDeferral
} from "../actions/plans/primaryPlanActions";
import { updateProjectedIncome } from "../actions/projectedIncome/projectedIncomeActions";
import { setShowReviewButton, setShowTradeOffs } from "../actions/reviewReset/reviewResetActions";
import { setCurrentFault } from "../actions/shared/sharedActionCreators";
import { setParticipantStartRecAge } from "../actions/socialSecurity/socialSecurityActions";
import Deferrals, { hasDeferralWarnings } from "../business/deferralsLogic/deferrals";
import { equityMixChangeOnly } from "../business/retirementIncome";
import { loadNextSteps } from "../middleware/nextStepMiddleware";
import { updateSSStartAge } from "../middleware/socialSecurityMiddleware";
import { submit } from "../services/reviewChangesService";
import { saveUserPreferencesService } from "../services/userPreferencesService";
import { getPAEFault, getPAEPromise } from "../utils/paeUtils";
import { validateSocialSecurityAge } from "../utils/socialSecurity";

export const saveChanges = () => {
    return (dispatch, getState) => {
        const state = getState();
        const clientId = state.participant.clientId;
        const individualId = state.participant.individualId;
        const investorId = state.participant.investorId;
        const isTypeParticipant = state.participant.type === "PARTICIPANT";
        const confidence = state.applicationSettings.projectionSettings.confidence;
        const planId = state.primaryPlan.id;
        const retirementAge = state.participant.retirementAge;
        const equityMix = state.investments.equityMix;
        const deferralWarnings = state.deferralSetupConfig.deferralWarnings;
        const deferralSubmissionWarnings = state.deferralSetupConfig.deferralSubmissionWarnings;
        const personId = state.participant.personId || "";
        const deferrals = state.deferralSetupConfig.changedDeferralsFull;
        const baseline = state.baseline;
        const priorRetirementAge = baseline.retirementAge;
        const priorEquityMix = baseline.equityMix;
        const individualOptions = state.investments.individualOptions;
        const hasDeferralChanged = state.deferralSetupConfig.hasDeferralChanged;
        const baselineDeferrals = baseline.deferrals;
        const { socialSecurityUser } = state.socialSecurity;

        const config = {
            clientId,
            individualId,
            investorId,
            personId,
            isTypeParticipant,
            planId,
            retirementAge,
            equityMix,
            deferrals,
            confidence,
            hasDeferralChanged,
            deferralWarnings
        };

        const submitChangesResult = (result) => {
            const tempVariable = state.participant.salary.tempVariable;
            const primaryPlan = state.primaryPlan;
            const deferralSetupConfig = state.deferralSetupConfig;
            const activeDeferral = state.activeDeferral;
            const deferralService = new Deferrals(
                result.deferrals,
                primaryPlan,
                deferralSetupConfig,
                activeDeferral
            );
            const hasChangedMaximizer =
                primaryPlan.maximizer && primaryPlan.maximizer.isUnenrolling;

            if (!ObjectUtil.isUndefinedOrNull(result)) {
                if (result.deferrals && result.deferrals.length) {
                    if (tempVariable && deferralService.getNonZeroVariableDeferralCount()) {
                        let newSalObj;

                        const saveSalaryResult = () => {
                            newSalObj = {
                                tempVariable
                            };

                            dispatch(updateParticipantSalary(newSalObj));
                        };

                        const saveSalaryFault = () => {
                            newSalObj = {
                                variable: 0,
                                tempVariable: 0
                            };

                            dispatch(updateParticipantSalary(newSalObj));
                        };

                        const config = {
                            userInfo: {
                                personId,
                                variable: tempVariable
                            },
                            changeBooleans: { salary: true }
                        };

                        saveUserPreferencesService(individualId, config).then(
                            saveSalaryResult,
                            saveSalaryFault
                        );
                    }

                    if (deferralSetupConfig.changedDeferralsFull.length > 0 && isTypeParticipant) {
                        // save confirmation data for confirmation page
                        dispatch(setConfirmationData(result.confirmationData));
                    }

                    // Save baseline in lastChanges before we update it below.
                    // Comparison will be used in confirmation page using lastChanges and current state.
                    dispatch(
                        setLastChanges({
                            retirementAge: baseline.retirementAge,
                            equityMix: baseline.investments.equityMix,
                            deferrals: baseline.deferrals
                        })
                    );

                    // update original deferrals with new values
                    const updatedBaslineDeferralsList = _cloneDeep(baselineDeferrals);

                    for (let i = 0; i < result.deferrals.length; i++) {
                        const indx = _findIndex(updatedBaslineDeferralsList, function (item) {
                            return item.deferralTypeCode === result.deferrals[i].deferralTypeCode;
                        });
                        if (indx > -1) {
                            // if autoincrease cancelled, remove autoincrease object in deferral
                            if (
                                !ObjectUtil.isUndefinedOrNull(result.deferrals[i].autoIncrease) &&
                                result.deferrals[i].value >=
                                    result.deferrals[i].autoIncrease.stopAtValue
                            ) {
                                result.deferrals[i].autoIncrease = null;
                                dispatch(clearAutoIncreaseOrFutureDated(result.deferrals[i], true));
                            } else if (
                                !ObjectUtil.isUndefinedOrNull(result.deferrals[i].futureDated)
                            ) {
                                result.deferrals[i].futureDated = null;
                                dispatch(
                                    clearAutoIncreaseOrFutureDated(result.deferrals[i], false)
                                );
                            }

                            updatedBaslineDeferralsList[indx] = result.deferrals[i];
                        }
                    }
                    // For testing
                    window.dispatchEvent(
                        new CustomEvent("pc_liatdatachanged", {
                            detail: { empowerPersonDeferredElections: true }
                        })
                    );

                    dispatch(setBaselineDeferrals(updatedBaslineDeferralsList));

                    // set the flag for enrolled in maximizer to false
                    if (hasChangedMaximizer) {
                        dispatch(setMaximizerIsOngoing(false));
                    }

                    dispatch(setupSummarySlider());
                    dispatch(loadNextSteps());
                } else if (
                    equityMixChangeOnly(
                        false,
                        deferrals,
                        priorRetirementAge,
                        retirementAge,
                        individualOptions,
                        priorEquityMix,
                        equityMix,
                        deferralWarnings,
                        deferralSubmissionWarnings
                    )
                ) {
                    dispatch(setShowConfirmModal(true));
                }

                if (result.retirementAge) {
                    dispatch(
                        setLastChanges({
                            retirementAge: baseline.retirementAge,
                            equityMix: baseline.investments.equityMix,
                            deferrals: baseline.deferrals
                        })
                    );

                    dispatch(setBaselineRetirementAge(result.retirementAge));
                    dispatch(setRetirementAge(result.retirementAge));
                    if (socialSecurityUser.atRetirement) {
                        const ssAge = validateSocialSecurityAge(result.retirementAge);
                        dispatch(setParticipantStartRecAge(ssAge));
                        dispatch(updateSSStartAge(ssAge, result.retirementAge));
                    }
                }

                if (result.equityMix) {
                    dispatch(setBaselineEquityMix(result.equityMix));
                    dispatch(setEquityMix(result.equityMix));
                }

                if (result.deferralRestrictions) {
                    dispatch(setDeferralRestrictions(result.deferralRestrictions));
                    dispatch(
                        setBaselineDeferrals(
                            result.deferrals.map(
                                (deferral) => (deferral.config.defrlAvailCode = "MODEL")
                            )
                        )
                    );
                    dispatch(setupSummarySlider());
                    dispatch(setAllowDeferral(false));
                }
            }

            // setting baseline projected income
            dispatch(updateProjectedIncome(true));
            dispatch(setShowReviewButton(false));
            return result;
        };

        const submitChangesFault = (fault) => {
            dispatch(setCurrentFault(fault));
            return Promise.reject(fault);
        };

        if (deferralWarnings.length) {
            dispatch(setParticipantDeferrals(baselineDeferrals));
        }

        if (!isTypeParticipant) {
            const paePromise = getPAEPromise();
            dispatch(setCurrentFault(getPAEFault()));

            return paePromise.then(submitChangesResult, submitChangesFault);
        } else {
            return submit(config).then(submitChangesResult, submitChangesFault);
        }
    };
};

export const resetSliderValues = () => {
    return (dispatch, getState) => {
        const { baseline, participant, primaryPlan, socialSecurity } = getState();

        const { deferrals, retirementAge } = baseline;
        const priorEquityMix = baseline.investments.equityMix;

        const { individualId } = participant;
        const { socialSecurityUser } = socialSecurity;

        batch(() => {
            // reset sliders and update projected income
            dispatch(setRetirementAge(retirementAge));
            if (socialSecurityUser.atRetirement) {
                const ssAge = validateSocialSecurityAge(retirementAge);
                dispatch(setParticipantStartRecAge(ssAge));
            }
            dispatch(setEquityMix(priorEquityMix));
            deferrals.forEach((item) => {
                item.priorValue = undefined;
            });

            dispatch(setParticipantDeferrals(deferrals));
            dispatch(initializeDeferralSetupConfigParams(deferrals));
            dispatch(setOriginalDeferralSummaryTotal(deferrals));
            dispatch(resetChangedDeferrals(true, true, true));
            dispatch(setEquityMixWarnings(individualId, primaryPlan, null));
            dispatch(setShowConfirmModal(false));
            dispatch(setShowReviewButton(false));
            dispatch(setShowTradeOffs(false));
            dispatch(clearAutoIncreaseOrFutureDated());

            if (primaryPlan.maximizer && primaryPlan.maximizer.isOngoing) {
                dispatch(deferralChangedPrimaryPlanMaximzerUnEnrolling(false));
            }

            dispatch(updateProjectedIncome(true));
            dispatch({ type: RESET_HAS_DEFERRAL_CHANGED });
        });
    };
};

export const confirmDone = (individualId, plan, updatedDeferrals, deferralWarnings) => {
    return (dispatch, getState) => {
        const participant = getState().participant;
        // If there are warnings deferral should not update
        const deferrals = hasDeferralWarnings(deferralWarnings)
            ? participant.deferrals
            : updatedDeferrals;

        dispatch(setConfirmationData(null));
        dispatch(
            setLastChanges({
                retirementAge: null,
                equityMix: 0,
                deferrals: []
            })
        );

        dispatch(setParticipantDeferrals(deferrals, true));
        dispatch(resetChangedDeferrals(true, true, true));
        dispatch(setEquityMixWarnings(individualId, plan, null));

        dispatch(updateProjectedIncome());

        if (plan.maximizer && plan.maximizer.isOngoing) {
            dispatch(deferralChangedPrimaryPlanMaximzerUnEnrolling(false));
        }

        dispatch(setShowConfirmModal(false));
    };
};

export const resetContributionSlidersOnly = () => {
    return (dispatch, getState) => {
        const { baseline } = getState();
        const { deferrals } = baseline;

        batch(() => {
            deferrals.forEach((item) => {
                item.priorValue = undefined;
            });

            dispatch(setParticipantDeferrals(deferrals));
            dispatch(setOriginalDeferralSummaryTotal(deferrals));
            dispatch(resetChangedDeferrals(true, true, true));
        });
    };
};
