import { useQuery } from "@tanstack/react-query";

import type IQuickViewAccount from "../../components/quickView/types";
import { getQuickViewAccountData } from "../../services/accountSummaryService";
import { QUERY_KEYS } from "../constants";

const useQuickViewAccounts = () => {
    return useQuery<IQuickViewAccount[]>({
        queryKey: [QUERY_KEYS.GET_QUICK_VIEW_ACCOUNTS_DATA],
        queryFn: getQuickViewAccountData
    });
};

export default useQuickViewAccounts;
