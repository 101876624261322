import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";

const userHasWcirDefault = (userGuid: string): boolean => {
    if (!userGuid) {
        console.error("userGuid is null or undefined");
        return false;
    }
    const translations = selectTranslations("whenCanIRetire");

    const setDefaultView = translations.experimentalView;

    if (!setDefaultView || setDefaultView.length === 0) {
        return false;
    }

    const lastCharacter = userGuid.slice(-1);
    const setDefaultViewLower = setDefaultView.map((char: string) => char.toLowerCase()); // Convert setDefaultView to lowercase

    // Check if the last character matches the setDefaultView
    const result = setDefaultViewLower.includes(lastCharacter.toLowerCase());

    return result;
};

export default userHasWcirDefault;
