import React from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { NumericFormat } from "react-number-format";
import { generatePath } from "react-router-dom";

import ReferenceData from "../../../../constants/ReferenceData";
import EventBusEvents from "../../../../events/eventBusEvents";
import { convertToShortFormat } from "../../../../utils/dateUtils";
import type IQuickViewAccount from "../../../quickView/types";
import { getLatestTransactions } from "../../helpers";
import type { QuickViewTranslations } from "../../types";

type LatestTransactionProps = {
    planDetails: IQuickViewAccount;
    translations: QuickViewTranslations;
};

const LatestTransaction = ({ planDetails, translations }: LatestTransactionProps) => {
    // Early return null if there are no latest transaction
    if (!planDetails?.lastTxnHistory) {
        return null;
    }

    const latestTransactions = getLatestTransactions({
        quickViewData: planDetails,
        type: translations.txnType
    });

    const href = generatePath(ReferenceData.URL_HYPERLINK_TRANSACTION_HISTORY, {
        individualId: planDetails.indId,
        groupId: planDetails.gaId
    });

    const onClickLatestTransaction = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_LINK,
            selection: EventBusEvents.QUICK_VIEW.LAST_TRANSACTION_CLICKED
        });
        window.location.href = href;
    };

    return (
        <div className="transaction-container">
            <a onClick={onClickLatestTransaction} href={href} className="transaction-title">
                {translations.latestTransaction.title}
            </a>
            <div className="transaction-categories">
                {latestTransactions.map((transaction, index) => (
                    <div className="transaction-categories-item" key={transaction.name}>
                        <div className="transaction-category">
                            <div>
                                <p className="transaction-category-name">{transaction.name}</p>
                                <p className="transaction-category-date">
                                    {convertToShortFormat(transaction.date)}
                                </p>
                            </div>
                            <p className="transaction-category-amount">
                                <NumericFormat
                                    fixedDecimalScale
                                    decimalScale={2}
                                    prefix={parseFloat(transaction.amount) > 0 ? "+$" : "$"}
                                    thousandSeparator=","
                                    displayType="text"
                                    value={transaction.amount}
                                />
                            </p>
                        </div>
                        {index !== latestTransactions.length - 1 && (
                            <span aria-hidden="true" className="separator"></span>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LatestTransaction;
