import React from "react";

type Props = {
    size?: number;
};

const NonMaxMatchIcon = ({ size = 20 }: Props) => {
    return (
        <svg
            aria-hidden="true"
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 19H23L11.5 0L0 19ZM12.5455 16H10.4545V14H12.5455V16ZM12.5455 12H10.4545V8H12.5455V12Z"
                fill="#D6281F"
            />
        </svg>
    );
};

export default NonMaxMatchIcon;
