import React from "react";

import type IQuickViewAccount from "../../../quickView/types";
import type { QuickViewTranslations } from "../../types";

import { MaxMatchIcon, NonMaxMatchIcon } from "./icons";

type MatchProps = {
    maxMatch: IQuickViewAccount["maxMatch"];
    translations: QuickViewTranslations;
};

const Match = ({ maxMatch, translations }: MatchProps) => {
    if (typeof maxMatch !== "boolean") {
        return null;
    }

    return (
        <div className="alert-wrapper">
            {maxMatch ? <MaxMatchIcon /> : <NonMaxMatchIcon size={22} />}
            <p className="alert-label">
                {maxMatch
                    ? translations.match.maximized.label
                    : translations.match.nonMaximized.label}
            </p>
        </div>
    );
};

export default Match;
