import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { csrfAtom } from "../../atoms/atoms";
import { getHistories } from "../../services/otherAssetsService";
import { QUERY_KEYS } from "../constants";

export interface GetHistoriesParams {
    endDate: string;
    intervalType: string;
    startDate: string;
    types: string;
    userAccountIds: string;
}

const useGetHistories = (options?: GetHistoriesParams) => {
    const csrf = useAtomValue(csrfAtom);

    return useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [QUERY_KEYS.GET_HISTORIES],
        queryFn: async () => {
            const response = await getHistories(csrf, options);
            return response.data;
        },
        enabled: Boolean(csrf) && !!options
    });
};

export default useGetHistories;
