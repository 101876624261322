import { useQuery } from "@tanstack/react-query";
import { retrieveAccountMenuDetails } from "core-ui/client/react/core/services/menuService";

import { QUERY_KEYS } from "../constants";

export interface IAccountMenuDetails {
    accountURL: string;
    accuCode: string;
    currentContext: boolean;
    dbName: string;
    groupId: string;
    individualId: string;
    planName: string;
    shadowPlan: boolean;
}

const useGetAccountMenuDetails = () => {
    return useQuery<IAccountMenuDetails[]>({
        queryKey: [QUERY_KEYS.GET_ACCOUNT_MENU_DETAILS],
        queryFn: async () => {
            return retrieveAccountMenuDetails();
        }
    });
};

export default useGetAccountMenuDetails;
