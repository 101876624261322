import React, { useState, memo, useCallback, useMemo } from "react";

import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { SliderWrapper } from "gw-shared-components";
import { omit } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import {
    RETIREMENT_INCOME_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    PRE_RETIREMENT_ROUTE,
    WHEN_CAN_I_RETIRE_ROUTE
} from "../../../routes";
import { handleRetirementAgeSliderChange } from "../../actions/investments/investmentsActions.js";
import { dispatchEventBus, dispatchAmplitude } from "../../actions/shared/sharedActionCreators";
import { setSliderMovement } from "../../actions/slider/sliderActions";
import { getMaxEditableEntry } from "../../business/deferralsLogic/deferrals";
import { isNonAllMoneyTypeGroupingsFlow } from "../../business/investments";
import { ariaControlsSlidersHomePage } from "../../constants/AccessibilityPaycheck";
import EventBusEvents from "../../events/eventBusEvents";
import { useAccountSummary } from "../../queries";
import {
    reselectCanShowInvestmentsSlider,
    reselectShowSocialSecurity,
    reselectParticipantAges
} from "../../selectors/reselectSelectors";
import { Investments } from "../investments/Investments";
import Maximizer from "../maximizer/Maxmizer";
import NextStep from "../nextSteps/NextStep";
import ReviewReset from "../reviewReset/ReviewReset";
import MultiSlider from "../sliderRouter/MultiSlider";

import { getAccountSummaryAccounts, showPlanLabel } from "./helpers";

const RETIREMENT_AGE_SLIDER_IDENTIFIER = "retirement-age-slider"; // these are currently static values as is
const RETIREMENT_AGE_SLIDER_ID = "retirementAge"; // these are currently static values as is

const COLLAPSED_ROUTES = [
    RETIREMENT_INCOME_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    PRE_RETIREMENT_ROUTE,
    WHEN_CAN_I_RETIRE_ROUTE
];

const RetirementIncomeView = () => {
    const dispatch = useDispatch();
    const retirementIncomePath = useRouteMatch(COLLAPSED_ROUTES);

    const translations = selectTranslations("retirementIncome");
    const paycheckTranslations = selectTranslations("paycheck");
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const individualOptions = useSelector((state) => state.investments.individualOptions);
    const activeDeferral = useSelector((state) => state.activeDeferral);
    const projectedIncome = useSelector((state) => state.projectedIncome);
    const canShowInvestmentsSlider = useSelector(reselectCanShowInvestmentsSlider);
    const isCachedApplication = useSelector((state) => state.shared.isCachedApplication);

    const isRetirementAgeSliderEnabled =
        !primaryPlan.planRules.specialCatchup && !isCachedApplication;
    const userShowSocialSecurity = useSelector(reselectShowSocialSecurity);
    const maxEditableEntry = getMaxEditableEntry(RETIREMENT_AGE_SLIDER_IDENTIFIER, activeDeferral);

    const [expandPaycheckOpen, setExpandPaycheckOpen] = useState(false);

    const { retirementAge, minRetirementAge, maxRetirementAge } =
        useSelector(reselectParticipantAges);

    const nonAllMoneyTypeGroupings = isNonAllMoneyTypeGroupingsFlow(individualOptions);
    const individualId = useSelector((state) => state.participant.individualId);
    const planList = useSelector((state) => state.participant.plans);
    const linkedAccounts = useSelector((state) => state.participant.linkedAccountBalances);

    // possibly use the loading state here
    const { data: accountSummary } = useAccountSummary();
    const allAccounts = useMemo(() => getAccountSummaryAccounts(accountSummary), [accountSummary]);

    const is1Id = useSelector((state) => state.shared.txnAccess.is1Id);
    const sliderBeingMoved = useSelector((state) => state.slider).sliderBeingMoved;
    const retireAgeSSMsg = translations.ssMessage.replace("{{totalHealthCareExpenses}}", "");

    const handleEventBus = useCallback(
        (type, target, data) => {
            const payload = omit(data, ["action"]);
            dispatch(dispatchEventBus(type, target, payload));
            dispatch(
                dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.SELECT_SLIDER,
                    event_properties: {
                        selection: type,
                        ...payload
                    }
                })
            );
        },
        [dispatch]
    );

    const handleSliderMoving = (value) => {
        dispatch(setSliderMovement(value));
    };

    const sliderChange = useCallback(
        (slider, value) => {
            dispatch(
                handleRetirementAgeSliderChange({
                    slider,
                    value,
                    individualId,
                    primaryPlan,
                    nonAllMoneyTypeGroupings
                })
            );
        },
        [dispatch, individualId, primaryPlan, nonAllMoneyTypeGroupings]
    );

    const expandText = expandPaycheckOpen
        ? paycheckTranslations.collapse
        : paycheckTranslations.expand;

    const handleCollapseClick = useCallback(
        (event) => {
            setExpandPaycheckOpen(!expandPaycheckOpen);
            const payload = { expanded: !expandPaycheckOpen };
            dispatch(dispatchEventBus(EventBusEvents.LIAT.EXPAND_TOGGLE, event.target, payload));
            dispatch(
                dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                    event_properties: {
                        selection: EventBusEvents.LIAT.EXPAND_TOGGLE,
                        ...payload
                    }
                })
            );
        },
        [dispatch, expandPaycheckOpen]
    );

    const { ariaValueTextPrefix = "" } = translations.sliders.retirementAge;
    const ariaDescribedBy = ariaControlsSlidersHomePage;

    const integratedAndExpandOpen = expandPaycheckOpen && retirementIncomePath;

    const subsetNamePlan = allAccounts.find((plan) => {
        return plan.subsetName === primaryPlan.subsetName;
    });
    const subsetName = subsetNamePlan?.subsetName ? `- ${subsetNamePlan.subsetName}` : "";
    const planLabelEnabled = showPlanLabel({ planList, linkedAccounts, allAccounts, is1Id });

    return (
        <div data-testid="retirement-income-view">
            {planLabelEnabled && (
                <div className="plan-label">
                    {primaryPlan.name}
                    {subsetName && (
                        <em>
                            <br />
                            {subsetName}
                        </em>
                    )}
                </div>
            )}
            <Maximizer />
            <div id="retirement-savings-slide" className="rivd-retirement-savings-slide">
                <div className="retirement-savings-slide-container">
                    <div className="retirement-sliders">
                        <MultiSlider />
                        {retirementAge && (
                            <div>
                                {integratedAndExpandOpen && (
                                    <div id="liat-expandable">
                                        <SliderWrapper
                                            ariaLabelText={
                                                translations.retirementAgeEditableTextAria
                                            }
                                            dispatchEventBus={handleEventBus}
                                            projectedIncome={
                                                projectedIncome ? projectedIncome.total : null
                                            }
                                            identifier={RETIREMENT_AGE_SLIDER_IDENTIFIER}
                                            ariaValueTextPrefix={ariaValueTextPrefix}
                                            sliderId={RETIREMENT_AGE_SLIDER_ID}
                                            //action to take if it is being moved
                                            sliderMoving={handleSliderMoving}
                                            //state of if it is being moved
                                            sliderBeingMoved={sliderBeingMoved}
                                            sliderChange={sliderChange}
                                            sliderTranslations={translations}
                                            min={minRetirementAge}
                                            max={maxRetirementAge}
                                            maxEditableValue={maxRetirementAge}
                                            minEditableValue={minRetirementAge}
                                            enabled={isRetirementAgeSliderEnabled}
                                            showMessage={userShowSocialSecurity}
                                            maxEditableEntry={maxEditableEntry}
                                            value={retirementAge}
                                            maxDecimals={0}
                                            showBottomText={false}
                                            ssMessage={retireAgeSSMsg}
                                            valueUnit={"years"}
                                            ariaDescribedBy={ariaDescribedBy}
                                        />
                                        {canShowInvestmentsSlider && <Investments />}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="ri-nextstep-review">
                    <NextStep expandPaycheckOpen={expandPaycheckOpen} />
                    <ReviewReset />
                </div>
                {retirementIncomePath && !isCachedApplication && (
                    <div className="expandable-container d-flex">
                        <button
                            data-testid="expand-button"
                            tabIndex="0"
                            type="button"
                            data-bs-toggle="collapse"
                            aria-expanded={expandPaycheckOpen}
                            onClick={handleCollapseClick}
                            disabled={isCachedApplication}
                            aria-disabled={isCachedApplication}
                            aria-controls="liat-expandable"
                            className="btn btn-link collapsible-link"
                        >
                            {expandText}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(RetirementIncomeView);
