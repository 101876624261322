/**
 *  Footer for Best Next Steps; This needs to be a separate component placed in RetirementSavingsView
 *  When sizing minimizes to iphone8, this footer display below all features, such as the slider, and invesments
 */

import React, { useState } from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { useSelector, useDispatch } from "react-redux";

import { setDeferralRules } from "../../actions/deferrals/deferralActions";
import { getShowTradeoffs, showReviewButton } from "../../actions/reviewReset/reviewResetActions";
import { setCurrentFault, dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import Deferrals from "../../business/deferralsLogic/deferrals";
import {
    shouldReplaceReviewChangesWithEquityMixPage,
    launchEquityMix,
    hasEquityMixChanged,
    hasRetirementAgeChanged,
    getHasEquityMixWarnings
} from "../../business/retirementIncome";
import EventBusEvents from "../../events/eventBusEvents";
import { useIsEmulator } from "../../hooks/useEmulatorType";
import {
    resetSliderValues,
    saveChanges,
    resetContributionSlidersOnly
} from "../../middleware/reviewChangesMiddleware";
import { reselectReviewLastChanges } from "../../selectors/reselectSelectors";
import { getPAEActionFault } from "../../utils/paeUtils";
import ConfirmChanges from "../confirmChanges/ConfirmChanges";
import { ReviewModalEvents } from "../reviewModal/ReviewModalEvents";

import HighContributionModal from "./HighContributionModal";

const ReviewReset = () => {
    const bestNextStepTranslations = selectTranslations("bestNextStep");
    const retirementIncomeTranslations = selectTranslations("retirementIncome");
    const reviewChangesTranslations = selectTranslations("reviewChanges");
    const originalRetireAge = useSelector((state) => state.baseline.retirementAge);
    const originalEquity = useSelector((state) => state.baseline.investments.equityMix);
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const individualOptions = useSelector((state) => state.investments.individualOptions);
    const activeDeferral = useSelector((state) => state.activeDeferral);
    const indivId = useSelector((state) => state.participant.individualId);
    const currentRetireAge = useSelector((state) => state.participant.retirementAge);
    const deferralSetupConfig = useSelector((state) => state.deferralSetupConfig);
    const deferrals = useSelector((state) => state.participant.deferrals);
    const planId = primaryPlan.id;
    const currentEquityMix = useSelector((state) => state.investments.equityMix);
    const equityMixWarnings = useSelector((state) => state.investments.equityMixWarnings);
    const deferralWarnings = useSelector((state) => state.deferralSetupConfig.deferralWarnings);
    const deferralSubmissionWarnings = useSelector(
        (state) => state.deferralSetupConfig.deferralSubmissionWarnings
    );
    const { priorEquityMix } = useSelector(reselectReviewLastChanges);

    // Functions that review changes page needed.
    const isPAEUser = useIsEmulator();
    const dispatch = useDispatch();

    const resetClick = (event) => {
        event.preventDefault();
        dispatch(resetSliderValues());

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: "reset_changes_button"
        });
    };

    const hasMixedPctAmt =
        deferralSetupConfig.hasDeferralChanged &&
        deferralSetupConfig.hasMixedDeferrals &&
        activeDeferral.isMixedDeferral;

    const reset = retirementIncomeTranslations.reset;
    const reviewChanges = bestNextStepTranslations.reviewChangesBtnLbl;
    const prevStateShowTradeOff = useSelector((state) => state.reviewChanges.showTradeOff);
    const prevStateShowReviewBtn = useSelector((state) => state.reviewChanges.showReviewButton);

    const deferralClass = new Deferrals(
        deferrals,
        primaryPlan,
        deferralSetupConfig,
        activeDeferral
    );

    getShowTradeoffs(
        prevStateShowTradeOff,
        deferrals,
        originalRetireAge,
        currentRetireAge,
        individualOptions,
        originalEquity,
        currentEquityMix,
        equityMixWarnings,
        dispatch
    );
    const showTradeoffs = useSelector((state) => state.reviewChanges.showTradeOff);
    showReviewButton(
        prevStateShowReviewBtn,
        deferrals,
        originalRetireAge,
        currentRetireAge,
        individualOptions,
        originalEquity,
        currentEquityMix,
        equityMixWarnings,
        dispatch
    );

    const showReviewButtonFlag = useSelector((state) => state.reviewChanges.showReviewButton);

    const { ignoreHighContributions } = useSelector((state) => state.shared.txnAccess);

    const [showHighContributionModal, setShowHighContributionModal] = useState(false);
    const [showWarningsOnlyModal, setShowWarningsOnlyModal] = useState(false);
    const [showConfirmModal, setShowConfirmationModal] = useState(false);

    const deferralWarningsHasLength = () => deferralWarnings && deferralWarnings.length > 0;
    const retirementAgeChanged = hasRetirementAgeChanged(originalRetireAge, currentRetireAge);

    // This function decides to show "warnings only" modal.
    const getWarningsOnlyModal = () => {
        const equityMixChanged = hasEquityMixChanged(
            false,
            individualOptions,
            originalEquity,
            currentEquityMix
        );
        const equityWarnings =
            hasEquityMixChanged(true, individualOptions, priorEquityMix, currentEquityMix) &&
            getHasEquityMixWarnings(equityMixWarnings);
        const fullDeferralsChanged = deferralClass.getChangedDeferrals("full").length > 0;
        const fullDeferralWarnings =
            deferralClass.getChangedDeferrals("full").length && deferralWarningsHasLength();
        const modelDeferralsChanged =
            deferralClass.getChangedDeferrals("model").length > 0 ||
            deferralClass.getChangedDeferrals("grandfathered").length > 0;
        const deferralWarningsOnly =
            (fullDeferralsChanged && fullDeferralWarnings) ||
            (!fullDeferralsChanged && modelDeferralsChanged);
        return (
            (deferralWarningsOnly && (equityWarnings || !equityMixChanged)) ||
            (equityWarnings && !fullDeferralsChanged)
        );
    };

    const deferralPercentTotal = deferrals.reduce((prev, deferral) => {
        if (
            deferralClass.isDollarDeferral(deferral) ||
            deferralClass.isVariableDeferral(deferral)
        ) {
            return prev;
        }
        return prev + deferral.value;
    }, 0);

    const submit = (event) => {
        event.preventDefault();
        dispatch(dispatchEventBus(EventBusEvents.LIAT.REVIEW_CHANGED_CLICK));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: EventBusEvents.LIAT.REVIEW_CHANGED_CLICK
        });

        // If the user is PAE, then don't process any modals, just show error message, and reset slider values.
        if (isPAEUser) {
            dispatch(resetSliderValues());
            dispatch(setCurrentFault(getPAEActionFault()));
            return;
        }

        if (
            shouldReplaceReviewChangesWithEquityMixPage(
                deferrals,
                originalRetireAge,
                currentRetireAge,
                individualOptions,
                originalEquity,
                currentEquityMix,
                equityMixWarnings,
                deferralWarnings,
                deferralSubmissionWarnings
            )
        ) {
            launchEquityMix(indivId, planId, currentEquityMix, individualOptions);
        } else {
            dispatch(setDeferralRules(deferrals, primaryPlan, deferralSetupConfig, activeDeferral));
        }

        if (hasMixedPctAmt) {
            dispatch(resetContributionSlidersOnly());
        }
        const isThereBrokenRules = dispatch(
            setDeferralRules(deferrals, primaryPlan, deferralSetupConfig, activeDeferral)
        );
        const showWarningsOnlyModal = getWarningsOnlyModal();
        const isHighContribution =
            !ignoreHighContributions &&
            deferralPercentTotal > reviewChangesTranslations.highContributionsThreshold;

        // This is the logic that decides which modal to show.
        // 3 different modals (High Contribution, Warnings Only, and Confirmation).
        // ConfirmChanges populates both WarningsOnly, and Confirmation modals.
        // High Contribution is on its own, but when you apply changes in the high contribution modal, it will take you to Confirmation Modal and submit your changes.
        if (
            showWarningsOnlyModal ||
            (showWarningsOnlyModal && isHighContribution) ||
            isThereBrokenRules.payload.length > 0
        ) {
            setShowWarningsOnlyModal(true);
            setShowConfirmationModal(true);
            setShowHighContributionModal(false);
            if (retirementAgeChanged && !isHighContribution) {
                dispatch(saveChanges(deferralClass));
                setShowConfirmationModal(true);
            } else if (retirementAgeChanged && isHighContribution) {
                setShowHighContributionModal(true);
                setShowWarningsOnlyModal(false);
                setShowConfirmationModal(false);
            }
        } else if (isHighContribution && !showWarningsOnlyModal) {
            setShowHighContributionModal(true);
            setShowWarningsOnlyModal(false);
            setShowConfirmationModal(false);
        } else {
            setShowConfirmationModal(true);
            dispatch(saveChanges(deferralClass));
            setShowWarningsOnlyModal(false);
            setShowHighContributionModal(false);
        }

        handleAnalyticsActions();
    };

    const handleAnalyticsActions = () => {
        // eventBus previously expected "this" as a primary argument, and "payload" as secondary argument
        // instead of passing the application/components state/props to GA, we will pass payload 2x in this event
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: ReviewModalEvents.SAVE_CLICK_EVENT,
            payload: { ...getPayload() }
        });

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: "submit_changes_button",
            payload: { ...getPayload(true) }
        });
    };

    const getPayload = (isAmplitude = false) => {
        const payload = {
            //! There's an issue with the value for the property below. It referece itself, creating a
            //! circular reference which don't allow to parse the value into JSON.
            //! For this reason is exclude from the payload
            // deferrals: deferralClass.getChangedDeferrals(DeferralConstants.category.FULL),
            retireAge: currentRetireAge,
            hasInvestmentChange: true,
            type: ReviewModalEvents.SAVE_CLICK_EVENT,
            source: ReviewModalEvents.REVIEW_CHANGES_MODAL
        };
        if (isAmplitude) {
            delete payload.type;
        }
        return payload;
    };

    return (
        <>
            {showTradeoffs && (
                <div
                    id="reviewReset"
                    className="pending-changes-container"
                    data-testid="review-reset"
                >
                    {
                        <div className="review-changes-notice">
                            {showReviewButtonFlag ? (
                                <span data-testid="review-change-note">
                                    {reviewChangesTranslations.submitNote}
                                </span>
                            ) : null}
                        </div>
                    }
                    <div className="review-changes-button-group">
                        <button
                            onClick={resetClick}
                            id="rivd-reset-btn"
                            className="btn btn-link rivd-changes-btn"
                            data-testid="reset-id"
                        >
                            {reset}
                        </button>
                        {showReviewButtonFlag && (
                            <button
                                className="btn rivd-changes-btn col-sm-8"
                                id="rivd-review-btn"
                                data-bs-toggle="modal"
                                onClick={(event) => submit(event)}
                                data-testid="review-changes-id"
                            >
                                {reviewChanges}
                            </button>
                        )}
                    </div>
                </div>
            )}
            {showConfirmModal && (
                <ConfirmChanges
                    showConfirmModal={showConfirmModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                    showWarningsOnlyModal={showWarningsOnlyModal}
                    setShowWarningsOnlyModal={setShowWarningsOnlyModal}
                    showHighContributionModal={showHighContributionModal}
                    deferralClass={deferralClass}
                    isHighContribution={
                        !ignoreHighContributions &&
                        deferralPercentTotal > reviewChangesTranslations.highContributionsThreshold
                    }
                    retirementAgeChanged={retirementAgeChanged}
                />
            )}
            {showHighContributionModal && (
                <HighContributionModal
                    showHighContributionModal={showHighContributionModal}
                    setShowHighContributionModal={setShowHighContributionModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                    deferralClass={deferralClass}
                />
            )}
        </>
    );
};

export default ReviewReset;
