import React from "react";

import Highcharts from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsReact from "highcharts-react-official";

import getAccounts2RefetchInterval from "../../../../containers/sidebarContainer/helpers/getAccounts2RefetchInterval";
import { useQuerySession, useGetAccounts2, useGetHistories } from "../../../../queries";
import getBalanceGraphHistories from "../../helpers/getBalanceGraphHistories";
import getPrimaryAccount from "../../helpers/getPrimaryAccount";
import type { QuickViewTranslations } from "../../types";

interface IBalanceGraph {
    gaId: string;
    translations: QuickViewTranslations;
}

const BalanceGraph = ({ gaId, translations }: IBalanceGraph) => {
    const { data: querySessionData } = useQuerySession();
    const { data: accounts2Data } = useGetAccounts2(
        getAccounts2RefetchInterval,
        querySessionData?.spHeader?.SP_HEADER_VERSION
    );

    const account = getPrimaryAccount(gaId, accounts2Data);

    const graphHistoriesOptions = account
        ? getBalanceGraphHistories(`[${account.userAccountId}]`)
        : undefined;
    const { data: historiesData } = useGetHistories(graphHistoriesOptions);

    const balances =
        account?.userAccountId && historiesData?.spData
            ? historiesData.spData.histories.map(({ balances }) => balances[account.userAccountId])
            : [];

    if (balances.length === 0) return;

    highchartsAccessibility(Highcharts);

    // For accessibility purposes
    const onChartLoad: Highcharts.ChartLoadCallbackFunction = function () {
        const svgGraph = this.container.querySelector("svg");
        if (svgGraph) {
            svgGraph.setAttribute("alt", translations.graph.balance.title);
            const descElement = svgGraph.querySelector("desc");
            if (descElement) {
                descElement.textContent = translations.graph.balance.title;
            }
        }
    };

    const chartOptions: Highcharts.Options = {
        title: {
            text: undefined
        },
        chart: {
            type: "area",
            showAxes: false,
            height: 156,
            spacing: [0, 0, 0, 0],
            events: {
                load: onChartLoad
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [
            {
                name: "balance",
                type: "area",
                animation: false,
                enableMouseTracking: false,
                data: balances,
                fillColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, "#007AFF12"],
                        [1, "#00499900"]
                    ]
                },
                lineWidth: 1,
                lineColor: "#007AFF",
                marker: {
                    enabled: false
                }
            }
        ],
        tooltip: {
            enabled: false
        },
        xAxis: {
            visible: false
        },
        yAxis: {
            visible: false
        }
    };

    return (
        <div data-testid="balance-graph">
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                allowChartUpdate={true}
            />
        </div>
    );
};

export default BalanceGraph;
