import { useState, useEffect, useCallback } from "react";

import { queryFocusableElements } from "core-ui/client/react/core/utils/accessibilityHelpers";
import { useMediaQuery } from "react-responsive";

import {
    ROUTE_OPEN_ACCOUNT_PS,
    ROUTE_OPEN_ACCOUNT_IRA,
    ROUTE_MANAGED_ACCOUNTS
} from "../../../../../routes";
import { ROUTES_WITH_NO_SIDEBAR } from "../../helpers/routesWithNoSideBar";

const useSideBarDisplay = (currentPath: string) => {
    const isTabletOrMobile = useMediaQuery({
        query: "(max-width: 1350px)"
    });

    const shouldHide = useCallback(() => {
        const isOpenAccountSubRoute =
            currentPath.startsWith(ROUTE_OPEN_ACCOUNT_PS) ||
            currentPath.startsWith(ROUTE_OPEN_ACCOUNT_IRA);
        return Boolean(
            currentPath !== ROUTE_MANAGED_ACCOUNTS &&
                (ROUTES_WITH_NO_SIDEBAR.indexOf(currentPath) > -1 || isOpenAccountSubRoute)
        );
    }, [currentPath]);

    const [isTabletOrMobileFlag, setIsTabletOrMobileFlag] = useState(isTabletOrMobile);
    const [hideSideBar, setHideSideBar] = useState(() => shouldHide());

    useEffect(() => {
        if (!isTabletOrMobileFlag) {
            const sideBarContent = document.querySelector("#sidebarContent > :not(.is-hidden)");
            sideBarContent?.querySelector<HTMLElement>(queryFocusableElements)?.focus();
        }
    }, [isTabletOrMobileFlag]);

    useEffect(() => {
        const updateHideSideBar = () => {
            //eslint-disable-next-line
            setHideSideBar(shouldHide());
        };
        updateHideSideBar();
    }, [currentPath, shouldHide]);

    const showSidebarClass = !isTabletOrMobile || !isTabletOrMobileFlag ? "show" : "";
    const showCollapsibleDiv = isTabletOrMobile && isTabletOrMobileFlag ? "show" : "hide";
    const subPageContainerClass = hideSideBar ? "subpage-no-sidebar-layout" : "";

    let slideClass = "";
    if (isTabletOrMobile) {
        slideClass = isTabletOrMobileFlag ? "slide-out" : "slide-in";
    }

    return {
        showSidebarClass,
        subPageContainerClass,
        showCollapsibleDiv,
        slideClass,
        hideSideBar,
        isTabletOrMobileFlag,
        setIsTabletOrMobileFlag
    };
};

export default useSideBarDisplay;
