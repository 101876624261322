import {
    ROUTE_OPEN_ACCOUNT,
    ROUTE_MANAGED_ACCOUNTS_NEW_MARKETING,
    ROUTE_MANAGED_ACCOUNTS_INTRO,
    ROUTE_MANAGED_ACCOUNTS_STARTED,
    ROUTE_MANAGED_ACCOUNTS_FIRST_USE_INTERVIEW,
    ROUTE_MANAGED_ACCOUNTS_ADVICE_NEW,
    ROUTE_MANAGED_ACCOUNTS_STARTED_NEW,
    ROUTE_MANAGED_ACCOUNTS_INTRO_NEW,
    ROUTE_MANAGED_ACCOUNTS_HOME_NEW,
    ROUTE_ONLINE_ADVICE_NEW,
    ROUTE_ONLINE_ADVICE_GET_STARTED,
    ROUTE_ONLINE_ADVICE_PREVIEW,
    ROUTE_OPEN_ACCOUNT_PS,
    ROUTE_OPEN_ACCOUNT_IRA,
    ROUTE_MANAGED_ACCOUNTS_NEW,
    ROUTE_PERSONAL_STRATEGY_PLUS_ABOUT,
    ROUTE_OPEN_ACCOUNT_OPTIONS,
    ROUTE_MARKETING_CONSENT,
    ROUTE_ROLLOVER,
    ROUTE_OPEN_BANK_ACCOUNT_V2,
    ROUTE_IRA_ACCOUNT_FUNDING
} from "../../../../routes";

export const ROUTES_WITH_NO_SIDEBAR = [
    ROUTE_OPEN_ACCOUNT,
    ROUTE_OPEN_ACCOUNT_PS,
    ROUTE_OPEN_ACCOUNT_IRA,
    ROUTE_MANAGED_ACCOUNTS_NEW_MARKETING,
    ROUTE_MANAGED_ACCOUNTS_INTRO,
    ROUTE_MANAGED_ACCOUNTS_STARTED,
    ROUTE_MANAGED_ACCOUNTS_NEW,
    ROUTE_MANAGED_ACCOUNTS_FIRST_USE_INTERVIEW,
    ROUTE_MANAGED_ACCOUNTS_ADVICE_NEW,
    ROUTE_MANAGED_ACCOUNTS_STARTED_NEW,
    ROUTE_MANAGED_ACCOUNTS_INTRO_NEW,
    ROUTE_MANAGED_ACCOUNTS_HOME_NEW,
    ROUTE_ONLINE_ADVICE_NEW,
    ROUTE_ONLINE_ADVICE_GET_STARTED,
    ROUTE_ONLINE_ADVICE_PREVIEW,
    ROUTE_PERSONAL_STRATEGY_PLUS_ABOUT,
    ROUTE_OPEN_ACCOUNT_OPTIONS,
    ROUTE_ROLLOVER,
    ROUTE_OPEN_BANK_ACCOUNT_V2,
    ROUTE_IRA_ACCOUNT_FUNDING,
    ROUTE_MARKETING_CONSENT
];
