import { useQuery } from "@tanstack/react-query";

import { getAccountSummary } from "../../services/accountSummaryService";
import type { IAccountSummary } from "../../types/common";
import { QUERY_KEYS } from "../constants";

const useAccountSummary = () => {
    return useQuery<IAccountSummary>({
        queryKey: [QUERY_KEYS.SUMMARY],
        queryFn: () => getAccountSummary()
    });
};

export default useAccountSummary;
