import type IQuickViewAccount from "../../types";
import { percentageStringFormatter } from "../index";

export const ONE_YEAR = 1;
export const THREE_YEARS = 3;
export const NO_YEAR_DIFFERENCE = 0;
const DAYS_IN_YEAR = 365;
const ONE_YEAR_IN_MILLISECONDS = DAYS_IN_YEAR * 24 * 60 * 60 * 1000;
const THREE_YEARS_IN_MILLISECONDS = 3 * ONE_YEAR_IN_MILLISECONDS;

const calculateRoRType = (
    planDetails: IQuickViewAccount | null | undefined
): {
    rate: number | string;
    ratePeriod: number;
} => {
    if (!planDetails || !planDetails.ror) {
        console.warn("There is No ROR object in planDetails object.");
        return {
            rate: 0,
            ratePeriod: 0
        };
    }

    const { adjustedBeginDate, adjustedEndDate, annualRateOfReturn, periodRateOfReturn } =
        planDetails.ror;

    if (!adjustedBeginDate || !adjustedEndDate || !annualRateOfReturn || !periodRateOfReturn) {
        console.warn("ROR object is missing required properties.");
        return {
            rate: 0,
            ratePeriod: 0
        };
    }

    const duration = adjustedEndDate - adjustedBeginDate;
    const isOverOneYear = duration >= ONE_YEAR_IN_MILLISECONDS;
    const isOverThreeYears = duration >= THREE_YEARS_IN_MILLISECONDS;

    const percentageString = isOverOneYear ? annualRateOfReturn : periodRateOfReturn;
    const ratePeriod = isOverThreeYears
        ? THREE_YEARS
        : isOverOneYear
          ? ONE_YEAR
          : NO_YEAR_DIFFERENCE;

    return {
        rate: percentageStringFormatter(percentageString),
        ratePeriod
    };
};

export default calculateRoRType;
