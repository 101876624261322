import React from "react";

import CurrencyFormatter from "../../../currencyFormatter/CurrencyFormatter";

type BalanceProps = {
    amount: number | undefined;
};

const Balance: React.FC<BalanceProps> = ({ amount }) => {
    return (
        <p className="quickview-balance">
            {amount || amount === 0 ? (
                <CurrencyFormatter awaitHubCalc={false} amount={amount} precision={0} />
            ) : (
                "N/A"
            )}
        </p>
    );
};

export default Balance;
