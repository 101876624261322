import { useMemo } from "react";

import { useGetAccountMenuDetails, useAccountSummary } from "../../../queries";
import {
    canShowQuickView,
    canShowQuickViewRedesign
} from "../../../selectors/featureFlagSelectors";
import { filterTrsPlans } from "../../../selectors/helpers/hasTrsPlan/hasTrsPlan";

const useShowQuickView = ({ homePageEligibility }: { homePageEligibility: boolean }) => {
    const { data: accountMenuDetails } = useGetAccountMenuDetails();
    const { data: accountSummary } = useAccountSummary();

    const isSingleContext = useMemo(() => {
        if (!accountMenuDetails) return false;
        return accountMenuDetails.every((item) => item.currentContext);
    }, [accountMenuDetails]);

    const nonTRSNonMonthlyAccounts = useMemo(() => {
        if (!accountSummary || !accountSummary.nonMonthlyAccounts) return [];
        return accountSummary.nonMonthlyAccounts.filter(filterTrsPlans);
    }, [accountSummary]);

    const isSingleAccount = useMemo(() => {
        if (!nonTRSNonMonthlyAccounts) return false;
        return nonTRSNonMonthlyAccounts.length === 1;
    }, [nonTRSNonMonthlyAccounts]);

    const isQuickViewEnabled = canShowQuickView(homePageEligibility); // true if WNQVD is ON
    const isQuickViewRedesignEnabled = canShowQuickViewRedesign(homePageEligibility); // true if WNQVR is ON

    const showQuickView = isQuickViewEnabled; // && !isSingleAccount; Allow users with more than one account to see the old quickview
    const showQuickViewRedesign = isQuickViewRedesignEnabled && isSingleContext && isSingleAccount;

    return { showQuickView, showQuickViewRedesign };
};

export default useShowQuickView;
